import React, { useEffect, useState } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
// import slideimg from "../../Assets/images/12 35.jpg";
// import CoverImg1 from "../../Assets/images/cover2.jpg";
// import CoverImg2 from "../../Assets/images/cover1.jpg";
import "../../Assets/Css/homeswiper.css";
import { ViewAllAdvertisement } from "../../Api/advertisement";
import { viewallCover } from "../../Api/cover";

const Homeswipper = () => {
  const [addata, setaddata] = useState([]);
  const [allcover, setallcover] = useState([]);
  useEffect(() => {
    getmydata();
  }, []);
  const getmydata = async () => {
    var alladvertisement = await ViewAllAdvertisement();
    setaddata(alladvertisement);
    var allcover = await viewallCover();
    setallcover(allcover);
  };
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentBannerIndex((prevIndex) =>
        prevIndex === addata.length - 1 ? 0 : prevIndex + 1
      );
    }, 10000); // 10000 milliseconds = 10 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [addata]);
  return (
    <>
      <div className="loginadd">
        {addata.map((image, index) => (
          <div
            key={index}
            style={{
              display: index === currentBannerIndex ? "block" : "none",
            }}
            className="advertisement-banner"
          >
            <img
              src={image.image}
              alt={`Banner ${index + 1}`}
              className="homeaddmobile"
            />
          </div>
        ))}
      </div>
      <div className="homeswiper">
        <div className="homeswiper1">
          <Swiper
            className="homeswiper2"
            // install Swiper modules
            modules={[Navigation]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
          >
            {allcover.length !== 0
              ? allcover.map((data, index) => (
                  <SwiperSlide className="slider" key={index}>
                    <img src={data.image} alt="" className="coverimaghome" />
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Homeswipper;
