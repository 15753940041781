import React from "react";
import Navbar from "../Component/Navbar";
import Trade from "../Component/Trade/Trade";
import Footer from "../Component/Footer";

const Mytrade = () => {
  return (
    <>
      <Navbar />
      <Trade />
      <Footer />
    </>
  );
};

export default Mytrade;
