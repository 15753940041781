import React from "react";
import Navbar from "../Component/Navbar";
import Marraige from "../Component/Information/Kulamlist";
import Footer from "../Component/Footer";

const Kulamlist = () => {
  return (
    <>
      <Navbar />
      <Marraige />
      <Footer />
    </>
  );
};

export default Kulamlist;
