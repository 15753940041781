import React from "react";
import Navbar from "../Component/Navbar";
import Footer from "../Component/Footer";
import Index from "../Component/History/Index";

const History = () => {
  return (
    <>
      <Navbar />
      <Index />
      <Footer />
    </>
  );
};

export default History;
