/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import "../../Assets/Css/Gallerycontact.css";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createContactus } from "../../Api/contactus";
import { CgMail } from "react-icons/cg";
import { BsFillTelephoneFill } from "react-icons/bs";

const Gallerycontact = () => {
  const [formdata, setformdata] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [clicked, setclicked] = useState(false);
  const handleformdata = (e) => {
    const { name, value } = e.target;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const submitbtn = async () => {
    if (formdata.name.length === 0) {
      toast.error("Please enter name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.email.length === 0) {
      toast.error("Please enter email...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.subject.length === 0) {
      toast.error("Please enter subject...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.message.length === 0) {
      toast.error("Please enter message...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      var createdata = await createContactus(formdata);
      if (createdata.message === "SUCCESS") {
        toast.success("Your Enquiry added Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
  };
  return (
    <>
      <div className="gallery-map">
        <div className="gallery-map1">
          <div className="map">
            <div className="map-locate">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d4384.479080311661!2d76.99234166178576!3d11.054742144104734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTHCsDAzJzEzLjgiTiA3NsKwNTknNDAuOSJF!5e1!3m2!1sen!2sin!4v1701861032026!5m2!1sen!2sin"
                width="600"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              <div className="newdiv">
                <div className="contactinfodiv">
                  <CgMail className="infologo" />
                  <h4 className="contactinfo">
                    thenmavattaokkaligarsangam@gmail.com
                  </h4>
                </div>
                <div className="contactinfodiv">
                  <BsFillTelephoneFill className="foot-icon" />
                  <h4 className="contactinfo">0422 3143231, +91 9790456785</h4>
                </div>
                <div className="contactinfodivnew mb-5">
                  <h4 className="contactinfonew">
                    {" "}
                    Office Time -Mon to Sat 10:00 to 05:30 , Sun 10:00 to 02:00
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="gallery-contacts">
            <h1>Contact us</h1>
            <div className="inputs">
              <input
                type="text"
                placeholder="Your Name"
                name="name"
                onChange={handleformdata}
              />
              <input
                type="email"
                placeholder="Your Email"
                id=""
                name="email"
                onChange={handleformdata}
              />
              <input
                type="text"
                placeholder="Subject"
                name="subject"
                onChange={handleformdata}
              />
              <div className="textcontent">
                <h1>Message here!</h1>
                <textarea
                  id=""
                  cols="30"
                  rows="10"
                  name="message"
                  onChange={handleformdata}
                ></textarea>
                {clicked === true ? (
                  <button>Please wait...</button>
                ) : (
                  <button onClick={submitbtn}>Submit</button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Gallerycontact;
