import { Familyfinction } from "../axios";

export const viewallFamilyfinction = async (data) => {
  var viewallFamilyfinction = await Familyfinction.post(
    `/viewallFamilyfunction`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallFamilyfinction;
};

export const createFamilyfinction = async (data) => {
  var createFamilyfinction = await Familyfinction.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createFamilyfinction;
};

export const updateFamilyfinction = async (data) => {
  var updateFamilyfinction = await Familyfinction.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateFamilyfinction;
};

export const destroyFamilyfinction = async (data) => {
  var destroyFamilyfinction = await Familyfinction.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyFamilyfinction;
};

export const viewByidFamilyfinction = async (data) => {
  var viewByidFamilyfinction = await Familyfinction.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidFamilyfinction;
};
