/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../Assets/Css/Meeting.css";
import { viewallMetting } from "../../Api/meeting";
import HTMLReactParser from "html-react-parser";
import moment from "moment";
import Meetingswiper from "../../Component/Meeting/Meetingswiper";
import { viewByidMember } from "../../Api/member";

const Meeting = () => {
  const [allmeeting, setallmeeting] = useState([]);
  const [allattendee, setallattendee] = useState([]);
  const [singlemeeting, setsinglemeeting] = useState([]);
  const [meetingid, setmeetingid] = useState(null);
  useEffect(() => {
    getAlldata();
  }, []);
  useEffect(() => {
    newdata();
  }, [singlemeeting]);
  const getAlldata = async () => {
    var alldata = await viewallMetting();
    if (alldata.length !== 0) {
      setallmeeting(alldata);
      setsinglemeeting([alldata[0]]);
      setmeetingid(alldata[0].id);
    }
  };
  const newdata = async () => {
    if (singlemeeting.length !== 0) {
      var attendeedata = JSON.parse(singlemeeting[0].attendees);
      if (attendeedata.length !== 0) {
        var alldata = [];
        for (var i = 0; i < attendeedata.length; i++) {
          var memberdata = await viewByidMember({ id: attendeedata[i] });
          if (memberdata.length !== 0) {
            alldata.push(memberdata[0]);
          }
        }
        setallattendee(alldata);
      }
    }
  };
  return (
    <>
      <div className="totaldiv">
        <div className="meetcontent">
          {singlemeeting.length !== 0 ? (
            <div className="meetimage">
              {JSON.parse(singlemeeting[0].images).length !== 0
                ? JSON.parse(singlemeeting[0].images).map((datanew, index) => (
                    <img src={datanew} alt="" key={index} />
                  ))
                : null}

              <div className="meetcont">
                <h3>{singlemeeting[0].name}</h3>
                <p>{moment(singlemeeting[0].date).format("MMMM DD, YYYY")}</p>
                <p>{HTMLReactParser(singlemeeting[0].description)}</p>
              </div>
            </div>
          ) : (
            <div className="meetimage"></div>
          )}

          <div className="meetscroll">
            <h1>Recent Meetings</h1>
            <div className="meetingscroll">
              {allmeeting.length !== 0
                ? allmeeting.map((data, index) => (
                    <div
                      className={
                        meetingid == data.id
                          ? "meetimgscroll activemeeting"
                          : "meetimgscroll"
                      }
                      key={index}
                      onClick={() => {
                        setmeetingid(data.id);
                        setsinglemeeting([data]);
                      }}
                    >
                      <img
                        src={JSON.parse(data.images)[0]}
                        alt=""
                        key={index}
                      />

                      <div className="meetdetail">
                        <h3>{data.name}</h3>
                        <p>{moment(data.date).format("MMMM DD, YYYY")}</p>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
      <Meetingswiper data={allattendee} />
    </>
  );
};

export default Meeting;
