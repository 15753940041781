/* eslint-disable no-unused-vars */
import React from "react";
import historyimg from "../../Assets/images/inverted-commas.png";
import history1img from "../../Assets/images/12 35.jpg";
import "../../Assets/Css/homehistory.css";
const Homehistory = () => {
  return (
    <>
      <div className="home-history">
        <div className="home-history1">
          <div className="history-content">
            <h1>HISTORY</h1>
            <p>Our community is at its best when we stick together.</p>
            <p name="send">
              ஒக்கலிகர் காமுகுல ஒக்கலிக கவுடர் (kamukula okkalika gowda)
              என்பவர்கள் தமிழ்நாட்டில் வாழும் ஒரு சாதியினராவர். இவர்கள்
              தமிழ்நாட்டின் தேனி, திண்டுக்கல், மதுரை, கோயமுத்தூர் ஆகிய
              மாவட்டங்களில் வாழ்கின்றனர். காமுகுல ஒக்கலிக கவுடர் குறிப்பிடத்தக்க
              மக்கள்தொகை கொண்ட பகுதிகள்-தமிழ் நாடு, கர்நாடகம் மொழி(கள்)-தமிழ்,
              கன்னடம் சமயம்-இந்து சமயம் தொடர்புள்ள இனக்குழுக்கள்-வொக்கலிகர்
              அனைத்துப் பிரிவும் இவர்கள் கன்னடமும், தமிழும் கலந்த மொழியைப்
              பேசுகிறார்கள். சில நூற்றாண்டுகளுக்கு முன்னர் அயலார்
              படையெடுப்பின்போது துங்கபத்திரை ஆற்றின் கரையில் இருந்து
              தமிழ்நாட்டுக்கு இவர்கள் குடிபெயர்ந்தவர்கள் என செவிவழி செய்தி
              இவர்களிடம் நிலவுகிறது.
            </p>
            {/* <div className="history-con">
              <img src={historyimg} alt="" />
              <p title="">
                Lorem ipsum dolor sit amet,lorem consectetur adipiscing elit,
                sed eiusmod.
              </p>
            </div> */}
          </div>
          <div className="history-images">
            <img src={history1img} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Homehistory;
