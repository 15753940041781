import React from "react";
import Navbar from "../Component/Navbar";
import Marraige from "../Component/Project/Marraige";
import Footer from "../Component/Footer";

const Marriges = () => {
  return (
    <>
      <Navbar />
      <Marraige />
      <Footer />
    </>
  );
};

export default Marriges;
