import { Event } from "../axios";

export const viewallEvent = async (data) => {
  var viewallEvent = await Event.post(`/viewallevent`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallEvent;
};

export const createEvent = async (data) => {
  var createEvent = await Event.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createEvent;
};

export const viewByidEvent = async (data) => {
  var viewByidEvent = await Event.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidEvent;
};

export const destroyEvent = async (data) => {
  var destroyEvent = await Event.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyEvent;
};
