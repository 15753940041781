/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../Assets/Css/Education.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import moment from "moment";
import HTMLReactParser from "html-react-parser";
import { viewByidEducationfund } from "../../Api/educationfund";
import { viewbyEducationfunddonor } from "../../Api/educationfunddonor";

const Fund = () => {
  const [projectdata, setprojectdata] = useState([]);
  const [donordata, setdonordata] = useState([]);

  var id = sessionStorage.getItem("fundid");
  useEffect(() => {
    getsingledata();
  }, [id]);
  const getsingledata = async () => {
    if (id !== null) {
      var singledata = await viewByidEducationfund({ id: id });
      setprojectdata(singledata);
      var donordata = await viewbyEducationfunddonor({ id: id });
      setdonordata(donordata);
    }
  };
  return (
    <>
      {projectdata.length !== 0 ? (
        <div className="fillpage">
          <div className="fillpage1">
            <div className="educnt">
              <div className="edu-image">
                <img src={JSON.parse(projectdata[0].image)[0]} alt="" />
              </div>
              <h3>{projectdata[0].title}</h3>
              <p>
                {moment(projectdata[0].date).format("MMMM DD, YYYY")} -{""}{" "}
                {projectdata[0].place}
              </p>
            </div>
            <div className="eduinfo">
              <p>{HTMLReactParser(projectdata[0].description)}</p>
            </div>
          </div>
          <div className="education-slider">
            <div className="edu-gallery">
              <h1>Gallery</h1>
              <div className="edu-gallery1">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={1}
                  loop={true}
                  autoplay={true}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                  }}
                >
                  {JSON.parse(projectdata[0].image).length !== 0
                    ? JSON.parse(projectdata[0].image).map((datanew, index) => (
                        <SwiperSlide className="edu-slider" key={index}>
                          <img src={datanew} alt="" />
                        </SwiperSlide>
                      ))
                    : null}
                </Swiper>
              </div>
            </div>
          </div>
          <div className="education-slider">
            <div className="edu-gallery">
              <h1>List of Donors</h1>
            </div>
          </div>
          <div className="edu-gallery">
            <table>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>City</th>
                <th>Date</th>
              </tr>
              {donordata.length !== 0
                ? donordata.map((data, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data.name}</td>
                      <td>{data.city}</td>
                      <td>{data.date}</td>
                    </tr>
                  ))
                : null}
            </table>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Fund;
