import { Metting } from "../axios";

export const viewallMetting = async (data) => {
  var viewallMetting = await Metting.post(`/viewallmeeting`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallMetting;
};

export const viewByidMetting = async (data) => {
  var viewByidMetting = await Metting.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidMetting;
};
