import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../../Assets/Css/meetingslider.css";

const meetingslider = ({ data }) => {
  return (
    <>
      <div className="meet-leader">
        <div className="meet-leader1">
          <div className="meet-leader2">
            <h1>ATTENDEES</h1>
            <p>
              All staff at community club are volunteers, we appreciate their
              dedication to the program keeping us operating.
            </p>
          </div>
        </div>
      </div>
      <div className="meetslider-scroll">
        <div className="meetslider-scroll1">
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            loop={true}
            autoplay={true}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {data.length !== 0
              ? data.map((datanew, index) => (
                  <SwiperSlide className="m-scroll" key={index}>
                    <div className="attent-images">
                      <img src={datanew.profile} alt="" />
                    </div>
                    <div className="meet-slide1">
                      <h1>
                        {datanew.firstname} {datanew.lastname}
                      </h1>
                      <h6>{datanew.role}</h6>
                    </div>
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default meetingslider;
