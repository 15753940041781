/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import "../Assets/Css/Navbar.css";
import navimg1 from "../Assets/images/2 135.png";
import { useNavigate } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";
import { HiMenu } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import Profilepic from "../Assets/images/profilepic.webp";
import { viewByidMember } from "../Api/member";
import { ViewAllAdvertisement } from "../Api/advertisement";

const Navbar = () => {
  const navigate = useNavigate();
  const [profile, setprofile] = useState(null);
  const [addata, setaddata] = useState([]);
  const [mobilemenu, setmobilemenu] = useState(false);

  var memberid =
    localStorage.getItem("memberid") || sessionStorage.getItem("memberid");
  const logoutbtn = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("/");
  };
  useEffect(() => {
    getmydata();
  }, []);
  const getmydata = async () => {
    var mydata = await viewByidMember({ id: memberid });
    if (mydata.length !== 0) {
      setprofile(mydata[0].profile);
    }
    var alladvertisement = await ViewAllAdvertisement();
    setaddata(alladvertisement);
  };

  const shuffledBanners = addata.slice().sort(() => Math.random() - 0.5);
  const halfLength = Math.ceil(shuffledBanners.length / 2);
  const bannerSet1 = shuffledBanners.slice(0, halfLength);
  const bannerSet2 = shuffledBanners.slice(halfLength);
  const [currentBannerIndexSet1, setCurrentBannerIndexSet1] = useState(0);
  const [currentBannerIndexSet2, setCurrentBannerIndexSet2] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentBannerIndexSet1((prevIndex) =>
        prevIndex === bannerSet1.length - 1 ? 0 : prevIndex + 1
      );
      setCurrentBannerIndexSet2((prevIndex) =>
        prevIndex === bannerSet2.length - 1 ? 0 : prevIndex + 1
      );
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, [bannerSet1, bannerSet2]);
  const selectmenu = async () => {
    setmobilemenu(!mobilemenu);
  };
  return (
    <>
      <div className="topbar">
        <h4
          className="emergencytag"
          onClick={() => {
            window.location.replace("/helpline");
          }}
        >
          Emergency Helpline
        </h4>
      </div>
      <div className="navbarflex">
        {bannerSet1.map((image, index) => (
          <div
            key={index}
            style={{
              display: index === currentBannerIndexSet1 ? "block" : "none",
            }}
            className="advertisement-banner"
          >
            <img
              src={image.image}
              alt={`Set1 Banner ${index + 1}`}
              className="navabrad"
            />
            <p className="addtag">Advertisement</p>
          </div>
        ))}
        <div
          className="navbar"
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={navimg1} alt="" />
          <p>Thenmaavatta Okkaligar (Kaappu) </p>
          <p>Mahajana Nala Sangam, Kovai</p>
        </div>
        {bannerSet2.map((image, index) => (
          <div
            key={index}
            style={{
              display: index === currentBannerIndexSet2 ? "block" : "none",
            }}
            className="advertisement-banner"
          >
            <img
              src={image.image}
              alt={`Set1 Banner ${index + 1}`}
              className="navabrad"
            />
            <p className="addtag">Advertisement</p>
          </div>
        ))}
      </div>

      <div className="navbar1">
        <div className="navbar2">
          <li
            className={window.location.pathname == "/" && "active"}
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </li>
          <li
            className={
              (window.location.pathname == "/history" && "active") ||
              (window.location.pathname == "/kulam" && "active") ||
              (window.location.pathname == "/kulamview" && "active")
            }
            name="list"
          >
            History
            <div className="down">
              <p
                onClick={() => {
                  navigate("/history");
                }}
              >
                History Of Okkaligar
              </p>
              <p
                onClick={() => {
                  navigate("/kulam");
                }}
              >
                Kulam & it’s history
              </p>
            </div>
          </li>

          <li
            className={
              (window.location.pathname == "/allevents" && "active") ||
              (window.location.pathname == "/event" && "active") ||
              (window.location.pathname == "/trade" && "active")
            }
            name="list"
          >
            Information
            <div className="down">
              <p
                onClick={() => {
                  navigate("/allevents");
                }}
              >
                Notice Board
              </p>
              {memberid !== null ? (
                <p
                  onClick={() => {
                    navigate("/trade");
                  }}
                >
                  Trade Directory
                </p>
              ) : null}
            </div>
          </li>
          <li
            onClick={() => {
              navigate("/news");
            }}
            className={window.location.pathname == "/news" && "active"}
          >
            News
          </li>
          <li
            onClick={() => {
              navigate("/office");
            }}
            className={window.location.pathname == "/office" && "active"}
          >
            Office Bearers
          </li>
          <li
            name="list"
            onClick={() => {
              navigate("/project");
            }}
            className={window.location.pathname == "/project" && "active"}
          >
            Projects
            <div className="down">
              <p
                onClick={() => {
                  navigate("/project");
                }}
              >
                Office
              </p>
              {/* <p>Education Support</p>
              <p>Marksheets</p> */}
              <p
                onClick={() => {
                  navigate("/project");
                }}
              >
                Family Function
              </p>
              <p
                onClick={() => {
                  navigate("/project");
                }}
              >
                Education Fund
              </p>
            </div>
          </li>
          {memberid !== null ? (
            <li
              onClick={() => {
                navigate("/meeting");
              }}
              className={window.location.pathname == "/meeting" && "active"}
            >
              {" "}
              Meetings
            </li>
          ) : null}

          {memberid !== null ? (
            <li
              className={window.location.pathname == "/myfamily" && "active"}
              onClick={() => {
                navigate("/myfamily");
              }}
            >
              MyFamily
            </li>
          ) : null}
          {memberid !== null ? (
            <li
              className={window.location.pathname == "/members" && "active"}
              onClick={() => {
                navigate("/members");
              }}
            >
              Members
            </li>
          ) : null}

          <li
            onClick={() => {
              navigate("/gallery");
            }}
            className={window.location.pathname == "/gallery" && "active"}
          >
            {" "}
            Contact Us
          </li>

          {memberid === null ? (
            <li
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </li>
          ) : (
            <>
              <li name="list">
                <img
                  src={profile === null ? Profilepic : profile}
                  alt=""
                  className="myprofile"
                />
                <div className="down">
                  <p
                    onClick={() => {
                      navigate("/myprofile");
                    }}
                  >
                    <AiOutlineUser /> <span> My Profile</span>
                  </p>
                  <p onClick={logoutbtn} className="logoutag">
                    <BiLogOut /> <span>Logout</span>
                  </p>
                </div>
              </li>
            </>
          )}
        </div>
      </div>
      <div className="mobile-nav">
        {mobilemenu === false ? (
          <>
            <input
              type="checkbox"
              id="check"
              onClick={() => {
                selectmenu();
              }}
            />
            <label htmlFor="check" className="checkbtn">
              <HiMenu className="mobile-icon" />
              <RxCross2 className="mobile-icon11" />
            </label>
            <div
              className="mobile-head"
              onClick={() => {
                window.location.replace("/");
              }}
            >
              <img src={navimg1} alt="" />
              <p>Thenmaavatta Okkaligar (Kaappu) </p>
              <p>Mahajana Nala Sangam, Kovai</p>
            </div>
          </>
        ) : (
          <div className="mobile-scroll">
            <RxCross2
              className="closeicon"
              onClick={() => {
                selectmenu();
              }}
            />
            <div>
              <h4
                className="emergencytag"
                onClick={() => {
                  window.location.replace("/helpline");
                }}
              >
                Emergency Helpline
              </h4>
            </div>
            {memberid !== null ? (
              <>
                <li name="list">
                  <img
                    src={profile === null ? Profilepic : profile}
                    alt=""
                    className="myprofile"
                  />
                  <div className="down">
                    <p
                      onClick={() => {
                        navigate("/myprofile");
                      }}
                    >
                      <AiOutlineUser /> <span> My Profile</span>
                    </p>
                    <p onClick={logoutbtn} className="logoutag">
                      <BiLogOut /> <span>Logout</span>
                    </p>
                  </div>
                </li>
              </>
            ) : null}
            <li
              className={window.location.pathname == "/" && "active"}
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </li>
            <li
              className={
                (window.location.pathname == "/history" && "active") ||
                (window.location.pathname == "/kulam" && "active") ||
                (window.location.pathname == "/kulamview" && "active")
              }
              name="list"
            >
              History
              <div className="down">
                <p
                  onClick={() => {
                    navigate("/history");
                  }}
                >
                  History Of Okkaligar
                </p>
                <p
                  onClick={() => {
                    navigate("/kulam");
                  }}
                >
                  Kulam & it’s history
                </p>
              </div>
            </li>

            <li
              className={
                (window.location.pathname == "/allevents" && "active") ||
                (window.location.pathname == "/event" && "active") ||
                (window.location.pathname == "/trade" && "active")
              }
              name="list"
            >
              Information
              <div className="down">
                <p
                  onClick={() => {
                    navigate("/allevents");
                  }}
                >
                  Notice Board
                </p>
                {memberid !== null ? (
                  <p
                    onClick={() => {
                      navigate("/trade");
                    }}
                  >
                    Trade Directory
                  </p>
                ) : null}
              </div>
            </li>

            <li
              onClick={() => {
                navigate("/office");
              }}
              className={window.location.pathname == "/office" && "active"}
            >
              Office Bearers
            </li>
            <li
              onClick={() => {
                navigate("/news");
              }}
              className={window.location.pathname == "/news" && "active"}
            >
              News
            </li>
            <li
              name="list"
              onClick={() => {
                navigate("/project");
              }}
              className={window.location.pathname == "/project" && "active"}
            >
              Project
              <div className="down">
                <p
                  onClick={() => {
                    navigate("/project");
                  }}
                >
                  Office
                </p>
                <p
                  onClick={() => {
                    navigate("/project");
                  }}
                >
                  Family Function
                </p>
                <p
                  onClick={() => {
                    navigate("/project");
                  }}
                >
                  Education Fund
                </p>
              </div>
            </li>
            {memberid !== null ? (
              <li
                onClick={() => {
                  navigate("/meeting");
                }}
                className={window.location.pathname == "/meeting" && "active"}
              >
                {" "}
                Meetings
              </li>
            ) : null}

            {memberid !== null ? (
              <li
                className={window.location.pathname == "/myfamily" && "active"}
                onClick={() => {
                  navigate("/myfamily");
                }}
              >
                MyFamily
              </li>
            ) : null}
            {memberid !== null ? (
              <li
                className={window.location.pathname == "/members" && "active"}
                onClick={() => {
                  navigate("/members");
                }}
              >
                Members
              </li>
            ) : null}

            <li
              onClick={() => {
                navigate("/gallery");
              }}
              className={window.location.pathname == "/gallery" && "active"}
            >
              {" "}
              Contact Us
            </li>
            {memberid === null ? (
              <li
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </li>
            ) : (
              <>
                <li name="list">
                  <img
                    src={profile === null ? Profilepic : profile}
                    alt=""
                    className="myprofile"
                  />
                  <div className="down">
                    <p
                      onClick={() => {
                        navigate("/myprofile");
                      }}
                    >
                      <AiOutlineUser /> <span> My Profile</span>
                    </p>
                    <p onClick={logoutbtn} className="logoutag">
                      <BiLogOut /> <span>Logout</span>
                    </p>
                  </div>
                </li>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
