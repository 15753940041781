import React from "react";
import Navbar from "../Component/Navbar";
import Footer from "../Component/Footer";
import Galleryslider from "../Component/Gallery/Galleryslider";
import Gallerycontact from "../Component/Gallery/Gallerycontact";

const Gallery = () => {
  return (
    <>
      <Navbar />
      <Galleryslider />
      <Gallerycontact />
      <Footer />
    </>
  );
};

export default Gallery;
