import { News } from "../axios";

export const viewallNews = async (data) => {
  var viewallNews = await News.post(`/viewallnews`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallNews;
};

export const createNews = async (data) => {
  var createNews = await News.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createNews;
};

export const viewByidNews = async (data) => {
  var viewByidNews = await News.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidNews;
};
