/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import "../../Assets/Css/style.css";
import { viewallOfficebearers } from "../../Api/officebearers";

const Sslides = () => {
  const [officemember, setofficemember] = useState([]);
  const [memberdata, setmemberdata] = useState([]);
  const [Advisorsdata, setAdvisorsdata] = useState([]);
  const [secretarydata, setsecretarydata] = useState([]);
  const [Treasurerdata, setTreasurerdata] = useState([]);
  const [executivedata, setexecutivedata] = useState([]);
  const [jointdata, setjointdata] = useState([]);
  const [pastdata, setpastdata] = useState([]);

  useEffect(() => {
    getofficedata();
  }, []);
  const getofficedata = async () => {
    var alldata = await viewallOfficebearers();
    console.log(alldata, "alldata");
    if (alldata.length !== 0) {
      var presidentdata = await alldata.filter((data) => {
        return data.role === "President";
      });
      var memberdata = await alldata.filter((data) => {
        return data.role === "Vice President";
      });
      var advismemberdata = await alldata.filter((data) => {
        return data.role === "Advisors";
      });
      var secretarydata = await alldata.filter((data) => {
        return data.role === "Secretary";
      });
      var Treasurerdata = await alldata.filter((data) => {
        return data.role === "Treasurer";
      });
      var executivedata = await alldata.filter((data) => {
        return data.role === "Executive Members";
      });
      var jointdata = await alldata.filter((data) => {
        return data.role === "Joint Secretary";
      });
      var pastdata = await alldata.filter((data) => {
        return data.role === "Past Office Bearers";
      });

      setmemberdata(memberdata);
      setofficemember(presidentdata);
      setAdvisorsdata(advismemberdata);
      setsecretarydata(secretarydata);
      setTreasurerdata(Treasurerdata);
      setexecutivedata(executivedata);
      setjointdata(jointdata);
      setpastdata(pastdata);
    }
  };
  return (
    <>
      <div className="home-scrolloffice">
        <div className="home-scroll1">
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            loop={true}
            autoplay={true}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
          >
            {officemember.length !== 0
              ? officemember.map((data, index) => (
                  <SwiperSlide className="sscroll" key={index}>
                    <img
                      src={data.profile}
                      alt=""
                      className="homeleaderimgoffice"
                    />
                    <div className="chairman-contentoffice">
                      <h4>
                        {data.firstname} {data.lastname}
                      </h4>
                      <h5 className="roletag">{data.role}</h5>
                    </div>
                  </SwiperSlide>
                ))
              : null}
            {memberdata.length !== 0
              ? memberdata.map((data, index) => (
                  <SwiperSlide className="sscroll" key={index}>
                    <img
                      src={data.profile}
                      alt=""
                      className="homeleaderimgoffice"
                    />
                    <div className="chairman-contentoffice">
                      <h4>
                        {data.firstname} {data.lastname}
                      </h4>
                      <h5 className="roletag">{data.role}</h5>
                    </div>
                  </SwiperSlide>
                ))
              : null}
            {Treasurerdata.length !== 0
              ? Treasurerdata.map((data, index) => (
                  <SwiperSlide className="sscroll" key={index}>
                    <img
                      src={data.profile}
                      alt=""
                      className="homeleaderimgoffice"
                    />
                    <div className="chairman-contentoffice">
                      <h4>
                        {data.firstname} {data.lastname}
                      </h4>
                      <h5 className="roletag">{data.role}</h5>
                    </div>
                  </SwiperSlide>
                ))
              : null}
            {secretarydata.length !== 0
              ? secretarydata.map((data, index) => (
                  <SwiperSlide className="sscroll" key={index}>
                    <img
                      src={data.profile}
                      alt=""
                      className="homeleaderimgoffice"
                    />
                    <div className="chairman-contentoffice">
                      <h4>
                        {data.firstname} {data.lastname}
                      </h4>
                      <h5 className="roletag">{data.role}</h5>
                    </div>
                  </SwiperSlide>
                ))
              : null}
            {Advisorsdata.length !== 0
              ? Advisorsdata.map((data, index) => (
                  <SwiperSlide className="sscroll" key={index}>
                    <img
                      src={data.profile}
                      alt=""
                      className="homeleaderimgoffice"
                    />
                    <div className="chairman-contentoffice">
                      <h4>
                        {data.firstname} {data.lastname}
                      </h4>
                      <h5 className="roletag">{data.role}</h5>
                    </div>
                  </SwiperSlide>
                ))
              : null}
            {jointdata.length !== 0
              ? jointdata.map((data, index) => (
                  <SwiperSlide className="sscroll" key={index}>
                    <img
                      src={data.profile}
                      alt=""
                      className="homeleaderimgoffice"
                    />
                    <div className="chairman-contentoffice">
                      <h4>
                        {data.firstname} {data.lastname}
                      </h4>
                      <h5 className="roletag">{data.role}</h5>
                    </div>
                  </SwiperSlide>
                ))
              : null}
            {pastdata.length !== 0
              ? pastdata.map((data, index) => (
                  <SwiperSlide className="sscroll" key={index}>
                    <img
                      src={data.profile}
                      alt=""
                      className="homeleaderimgoffice"
                    />
                    <div className="chairman-contentoffice">
                      <h4>
                        {data.firstname} {data.lastname}
                      </h4>
                      <h5 className="roletag">{data.role}</h5>
                    </div>
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
        </div>
      </div>
      <div className="gallerydiv">
        <h2 className="galleryhead">Executive Members</h2>
        <div className="gridsplitmember">
          {executivedata.length !== 0 ? (
            executivedata.map((data, index) => (
              <div key={index}>
                <img
                  src={data.profile}
                  alt=""
                  className="historyleadermember"
                />
                <h3 className="leadername">
                  {data.firstname} {data.lastname}
                </h3>
              </div>
            ))
          ) : (
            <div>
              <h4>No Executive Members Available...</h4>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Sslides;
