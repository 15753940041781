/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Navbar from "../Component/Navbar";
import Footer from "../Component/Footer";
import { viewByidKulam } from "../Api/kulam";
import HTMLReactParser from "html-react-parser";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Kulamview = () => {
  const [kulamdata, setkulamdata] = useState([]);

  var kulamid = sessionStorage.getItem("kulamid");
  useEffect(() => {
    getKulamdata();
  }, [kulamid]);
  const getKulamdata = async () => {
    var kulamdata = await viewByidKulam({ id: kulamid });
    setkulamdata(kulamdata);
  };
  return (
    <>
      <Navbar />
      <div
        className="backdiv"
        onClick={() => {
          window.location.replace("/kulam");
        }}
      >
        <FaLongArrowAltLeft className="backicon" />
        <h2>back</h2>
      </div>
      {kulamdata.length !== 0 ? (
        <div className="fillpage">
          <div className="fillpage1">
            <div className="educnt">
              <div>
                <img src={kulamdata[0].cover} alt="" className="kulamlogo" />
              </div>
            </div>
            <h3 className="kulamtitle">{kulamdata[0].name}</h3>
            <div className="eduinfo">
              <p>{HTMLReactParser(kulamdata[0].description)}</p>
            </div>
          </div>
          <div className="education-slider">
            <div className="edu-gallery">
              <h1>Gallery</h1>
              <div className="edu-gallery1">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={1}
                  loop={true}
                  autoplay={true}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                  }}
                >
                  {JSON.parse(kulamdata[0].images).length !== 0
                    ? JSON.parse(kulamdata[0].images).map((datanew, index) => (
                        <SwiperSlide className="edu-slider" key={index}>
                          <img src={datanew} alt="" />
                        </SwiperSlide>
                      ))
                    : null}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Footer />
    </>
  );
};

export default Kulamview;
