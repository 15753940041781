/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import memimages from "../../Assets/images/234 3.png";
import "../../Assets/Css/Memberdetail.css";
import { viewByidFamily } from "../../Api/family";

const Familydetails = () => {
  const [singledata, setsingledata] = useState([]);

  var id = sessionStorage.getItem("familydetailsid");
  useEffect(() => {
    getsingledata();
  }, [id]);
  const getsingledata = async () => {
    if (id !== null) {
      var singlemember = await viewByidFamily({ id: id });
      setsingledata(singlemember);
    }
  };
  const download = (data) => {
    window.open(data);
  };
  return (
    <>
      <div className="mem-detail">
        <div className="mem-detail1">
          <h1>Family Members</h1>
          <p>
            All staff at community club are volunteers, we appreciate their
            dedication to the program keeping us operating.
          </p>
        </div>
      </div>
      <div className="mem-detail2">
        {singledata.length !== 0 ? (
          <div className="mem-detail13">
            <div className="mem-images">
              <div className="mem-images1">
                <img
                  src={
                    singledata[0].profile == null
                      ? memimages
                      : singledata[0].profile
                  }
                  alt=""
                />
              </div>
              <div className="mem-contents">
                <h1>
                  {singledata[0].firstname} {singledata[0].lastname}
                </h1>
                <h3>{singledata[0].role}</h3>
              </div>
            </div>
            <div className="mem-table">
              <table>
                <thead>
                  <tr>
                    <th>D.O.B</th>
                    <td>{singledata[0].dob}</td>
                  </tr>
                  <tr>
                    <th>Phone</th>
                    <td>+91 {singledata[0].phone}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{singledata[0].email}</td>
                  </tr>
                  <tr>
                    <th>Occupation</th>
                    <td>{singledata[0].occupation}</td>
                  </tr>
                  <tr>
                    <th>State</th>
                    <td>
                      {" "}
                      {singledata[0].state === null ? "-" : singledata[0].state}
                    </td>
                  </tr>
                  <tr>
                    <th>City</th>
                    <td>
                      {" "}
                      {singledata[0].city === null ? "-" : singledata[0].city}
                    </td>
                  </tr>
                  <tr>
                    <th>Caste</th>
                    <td>
                      {" "}
                      {singledata[0].cast === null ? "-" : singledata[0].cast}
                    </td>
                  </tr>
                  <tr>
                    <th>Kulam</th>
                    <td>
                      {" "}
                      {singledata[0].kolom === null ? "-" : singledata[0].kolom}
                    </td>
                  </tr>
                  {/* <tr>
                    <th>Kothiram</th>
                    <td>
                      {singledata[0].kothiram === null
                        ? "-"
                        : singledata[0].kothiram}
                    </td>
                  </tr> */}
                  <tr>
                    <th>Jathagam</th>
                    <td>
                      {singledata[0].jathagam !== null ? (
                        <button
                          onClick={() => {
                            download(singledata[0].jathagam);
                          }}
                        >
                          Download
                        </button>
                      ) : (
                        "Not Available"
                      )}
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Familydetails;
