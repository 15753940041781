/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import "../../Assets/Css/Leader.css";
import { viewallLeaders } from "../../Api/leaders";
import HTMLReactParser from "html-react-parser";
import moment from "moment";

const Leader = () => {
  const [allleader, setallleader] = useState([]);
  const [singleleader, setsingleleader] = useState([]);
  const [leaderid, setleaderid] = useState(null);

  var id = sessionStorage.getItem("leaderid");
  useEffect(() => {
    getAlldata();
  }, [id]);
  const getAlldata = async () => {
    if (id === null) {
      var alldata = await viewallLeaders();
      if (alldata.length !== 0) {
        setallleader(alldata);
        setsingleleader([alldata[0]]);
        setleaderid(alldata[0].id);
      }
    } else {
      var alldatanew = await viewallLeaders();
      if (alldatanew.length !== 0) {
        var checkdata = await alldatanew.filter((data) => {
          return data.id == id;
        });
        setallleader(alldatanew);
        setsingleleader([checkdata[0]]);
        setleaderid(id);
      }
    }
  };
  return (
    <>
      <div className="wholediv">
        <div className="leadercontent">
          {singleleader.length !== 0 ? (
            <div className="leaderimage">
              <img src={singleleader[0].profile} alt="" />
              <div className="leadercont">
                <h3>{singleleader[0].name}</h3>
                <p>
                  {moment(singleleader[0].createdAt).format("MMMM DD, YYYY")}
                </p>
                <p>{HTMLReactParser(singleleader[0].description)}</p>
              </div>
            </div>
          ) : (
            <div className="leaderimage"></div>
          )}

          <div className="leaderscroll">
            <h1>Past Leaders</h1>
            <div className="leadscroll">
              {allleader.length !== 0
                ? allleader.map((data, index) => (
                    <div
                      className={
                        leaderid == data.id
                          ? "leedscroll activeleader"
                          : "leedscroll"
                      }
                      key={index}
                      onClick={() => {
                        setleaderid(data.id);
                        setsingleleader([data]);
                        sessionStorage.removeItem("leaderid");
                      }}
                    >
                      <img src={data.profile} alt="" />
                      <div className="leadsdetail">
                        <h3>{data.name}</h3>
                        <p>{data.role}</p>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Leader;
