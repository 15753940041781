import React from "react";
import Navbar from "../Component/Navbar";
import Leader from "../Component/Leader/Leader";
import Footer from "../Component/Footer";

const Myleaders = () => {
  return (
    <>
      <Navbar />
      <Leader />
      <Footer />
    </>
  );
};

export default Myleaders;
