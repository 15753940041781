import { Gallery } from "../axios";

export const viewallGallery = async (data) => {
  var viewallGallery = await Gallery.post(`/viewallgallery`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallGallery;
};

export const createGallery = async (data) => {
  var createGallery = await Gallery.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createGallery;
};
