import { Nofification } from "../axios";

export const viewBytrueNofification = async (data) => {
  var viewBytrueNofification = await Nofification.post(`/viewBytrue`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewBytrueNofification;
};

export const createNofification = async (data) => {
  var createNofification = await Nofification.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createNofification;
};
