import { Leaders } from "../axios";

export const viewallLeaders = async (data) => {
  var viewallLeaders = await Leaders.post(`/viewallleaders`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallLeaders;
};

export const createLeaders = async (data) => {
  var createLeaders = await Leaders.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createLeaders;
};

export const viewByidLeaders = async (data) => {
  var viewByidLeaders = await Leaders.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidLeaders;
};
