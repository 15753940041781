/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";
import "../../Assets/Css/Trade.css";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { viewallMaruthamalaioffice } from "../../Api/maruthamalaioffice";
import { viewallFamilyfinction } from "../../Api/familyfunction";
import { viewallEducationfund } from "../../Api/educationfund";

const Project = () => {
  const [tradelist, settradelist] = useState([
    { name: "Sangam Office", url: "/sangam" },
    { name: "Family Function", url: "/familyfunction" },
    { name: "Education Fund", url: "/fund" },
  ]);
  const [alldata, setalldata] = useState([]);
  const [type, settype] = useState(null);
  const [filterdata, setfilterdata] = useState([
    { name: "Sangam Office", url: "/sangam" },
    { name: "Family Function", url: "/familyfunction" },
    { name: "Education Fund", url: "/fund" },
  ]);
  const changetrade = async (e) => {
    if (e.length !== 0) {
      var filterdata = [];
      for (var i = 0; i < tradelist.length; i++) {
        if (tradelist[i].name.toLowerCase().match(e.toLowerCase())) {
          filterdata.push(tradelist[i]);
        }
      }
      setfilterdata(filterdata);
    } else {
      setfilterdata([]);
    }
  };
  const selecttag = async (e) => {
    document.getElementById("searchinput").value = e.name;
    setfilterdata([]);
    if (e.name === "Sangam Office") {
      var alldata = await viewallMaruthamalaioffice();
      setalldata(alldata);
      settype(e.name);
    }
    if (e.name === "Family Function") {
      var alldatafamily = await viewallFamilyfinction();
      setalldata(alldatafamily);
      settype(e.name);
    }
    if (e.name === "Education Fund") {
      var alldatafund = await viewallEducationfund();
      setalldata(alldatafund);
      settype(e.name);
    }
  };
  const viewbtn = async (data) => {
    window.location.replace(`/sangam`);
    sessionStorage.setItem("maruthamalaiid", data.id);
  };
  const viewbtn1 = async (data) => {
    window.location.replace(`/familyfunction`);
    sessionStorage.setItem("familyid", data.id);
  };
  const viewbtn2 = async (data) => {
    window.location.replace(`/fund`);
    sessionStorage.setItem("fundid", data.id);
  };
  return (
    <>
      <div className="Trade-head">
        <div className="trade-head1">
          <h1>Projects</h1>
        </div>
      </div>
      <div className="trade-search">
        <div className="trade-search1">
          <p>
            <input
              type="text"
              placeholder="Search Project Here !"
              onChange={(e) => {
                changetrade(e.target.value);
              }}
              id="searchinput"
            />
            <BiSearch className="trade-icon" />
          </p>
          <div className="search-scroll">
            {filterdata.length !== 0
              ? filterdata.map((data, index) => (
                  <p
                    name="search"
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      selecttag(data);
                    }}
                  >
                    {data.name}
                    <BsFillArrowRightCircleFill className="search-scroll-icon" />{" "}
                  </p>
                ))
              : null}
          </div>
        </div>
      </div>
      <div className="member-content">
        <div className="member-contents1">
          {/* <h1 className="listtag">
            Listed {alldata.length} Members on the selected Project
          </h1> */}
          {type === "Sangam Office" ? (
            <table>
              <tr>
                <th>S.No</th>
                <th>Project</th>
                <th>City</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
              {alldata.length !== 0
                ? alldata.map((data, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data.title}</td>
                      <td>{data.place}</td>
                      <td>{data.date}</td>
                      <td>
                        <button
                          className="viewmorebtn"
                          onClick={() => {
                            viewbtn(data);
                          }}
                        >
                          View More
                        </button>
                      </td>
                    </tr>
                  ))
                : null}
            </table>
          ) : null}
          {type === "Family Function" ? (
            <table>
              <tr>
                <th>S.No</th>
                <th>Project</th>
                <th>City</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
              {alldata.length !== 0
                ? alldata.map((data, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data.title}</td>
                      <td>{data.place}</td>
                      <td>{data.date}</td>
                      <td>
                        <button
                          className="viewmorebtn"
                          onClick={() => {
                            viewbtn1(data);
                          }}
                        >
                          View More
                        </button>
                      </td>
                    </tr>
                  ))
                : null}
            </table>
          ) : null}
          {type === "Education Fund" ? (
            <table>
              <tr>
                <th>S.No</th>
                <th>Project</th>
                <th>City</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
              {alldata.length !== 0
                ? alldata.map((data, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data.title}</td>
                      <td>{data.place}</td>
                      <td>{data.date}</td>
                      <td>
                        <button
                          className="viewmorebtn"
                          onClick={() => {
                            viewbtn2(data);
                          }}
                        >
                          View More
                        </button>
                      </td>
                    </tr>
                  ))
                : null}
            </table>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Project;
