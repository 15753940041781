import React from "react";
import Navbar from "../Component/Navbar";
import Companydetails from "../Component/Company/Companydetails";
import Footer from "../Component/Footer";

const Company = () => {
  return (
    <>
      <Navbar />
      <Companydetails />
      <Footer />
    </>
  );
};

export default Company;
