/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import "../../Assets/Css/Trade.css";
import tradeimg from "../../Assets/images/12 35.png";
import { BsTelephoneFill } from "react-icons/bs";
import { AiTwotoneMail } from "react-icons/ai";
import { viewallMember } from "../../Api/member";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

const Trade = () => {
  const [tradelist, settradelist] = useState([
    "Agricultural",
    "Alcohol/Beverages",
    "Animal Feed & Poultry",
    "Automobiles",
    "Builders",
    "Hardware",
    "Hospital",
    "Manufacturers & Merchandise traders",
    "Clinic/Lab",
    "Chemicals",
    "Computer Peripherals",
    "Consumer Electronics",
    "Cosmetics",
    "Electrical Equipment",
  ]);
  const [alldata, setalldata] = useState([]);
  const [filterdata, setfilterdata] = useState([]);
  const [finalarray, setfinalarray] = useState([]);
  const [searchvalue, setsearchvalue] = useState("");

  const changetrade = async (e) => {
    if (e.length !== 0) {
      var filterdata = [];
      for (var i = 0; i < tradelist.length; i++) {
        if (tradelist[i].toLowerCase().match(e.toLowerCase())) {
          filterdata.push(tradelist[i]);
        }
      }
      setfilterdata(filterdata);
    } else {
      setfilterdata([]);
    }
  };
  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    var alldata = await viewallMember();
    if (alldata.length !== 0) {
      var occupationlist = [];
      for (var i = 0; i < alldata.length; i++) {
        occupationlist.push(alldata[i].occupation);
      }
      var uniq = [...new Set(occupationlist)];
      settradelist(uniq);
    }
    setalldata(alldata);
  };
  const selecttag = async (e) => {
    document.getElementById("searchinput").value = e;
    var newarray = [];
    for (var i = 0; i < alldata.length; i++) {
      if (alldata[i].occupation == e) {
        newarray.push(alldata[i]);
      }
    }
    setfilterdata([]);
    setsearchvalue(e);
    setfinalarray(newarray);
  };
  const viewmember = async (id) => {
    window.location.replace(`/companydetails`);
    sessionStorage.setItem("memberdetailsid", id);
  };
  return (
    <>
      <div className="Trade-head">
        <div className="trade-head1">
          <h1>Trade Directory</h1>
          {/* <p>
            All staff at community club are volunteers, we appreciate their
            dedication to the program keeping us operating.
          </p> */}
        </div>
      </div>
      <div className="trade-search">
        <div className="trade-search1">
          <p>
            <input
              type="text"
              placeholder="Search Trade Directory Here !"
              onChange={(e) => {
                changetrade(e.target.value);
              }}
              id="searchinput"
              defaultValue={searchvalue}
            />
            <BiSearch className="trade-icon" />
          </p>
          <div className="search-scroll">
            {filterdata.length !== 0
              ? filterdata.map((data, index) => (
                  <p
                    name="search"
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      selecttag(data);
                    }}
                  >
                    {data}
                    <BsFillArrowRightCircleFill className="search-scroll-icon" />{" "}
                  </p>
                ))
              : null}
          </div>
        </div>
      </div>
      <div className="member-content">
        <div className="member-contents1">
          <h1>Listed {finalarray.length} Members on the selected category</h1>
          <div className="member-cards">
            {finalarray.length !== 0
              ? finalarray.map((data, index) => (
                  <div
                    className="member-card1"
                    key={index}
                    onClick={() => {
                      viewmember(data.id);
                    }}
                  >
                    <div className="member-box1">
                      <div className="member-img">
                        <img src={tradeimg} alt="" />
                      </div>
                      <div className="member-img-con">
                        <h2>
                          {data.firstname} {data.lastname}
                        </h2>
                        <p>{data.occupation}</p>
                      </div>
                    </div>
                    <div className="member-contact">
                      <p>
                        <BsTelephoneFill className="member-i" />
                        {data.phone}
                      </p>
                      <p>
                        <AiTwotoneMail className="member-i" />
                        <p>{data.email}</p>
                      </p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Trade;
