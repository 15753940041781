import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDogncVaraAcH1iEXj-wC7R33Hl-W4KBjY",
  authDomain: "community-7cb31.firebaseapp.com",
  projectId: "community-7cb31",
  storageBucket: "community-7cb31.appspot.com",
  messagingSenderId: "39952056666",
  appId: "1:39952056666:web:f1fc2d769f2c49dab93363",
};

firebase.initializeApp(firebaseConfig);
let auth = firebase.auth();
export { auth, firebase };
