import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../Assets/Css/homeleader.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import HTMLReactParser from "html-react-parser";
import { viewallLeaders } from "../../Api/leaders";

const Homeleader = () => {
  const [allleader, setallleader] = useState([]);
  useEffect(() => {
    getAlldata();
  }, []);
  const getAlldata = async () => {
    var alldata = await viewallLeaders();
    setallleader(alldata);
  };

  const viewleader = async (e) => {
    sessionStorage.setItem("leaderid", e);
    window.location.replace(`/leaders`);
  };
  return (
    <>
      <div className="home-leader">
        <div className="home-leader1">
          <div className="home-leader2">
            <h1>LEADERS</h1>
            {/* <p>
              All staff at community club are volunteers, we appreciate their
              dedication to the program keeping us operating.
            </p> */}
          </div>
        </div>
      </div>
      <div className="home-scroll">
        <div className="home-scroll1">
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            loop={true}
            autoplay={true}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {allleader.length !== 0
              ? allleader.map((data, index) => (
                  <SwiperSlide className="sscroll" key={index}>
                    <img src={data.profile} alt="" className="homeleaderimg" />
                    <div className="his-slide1">
                      <h1>{data.name}</h1>
                      <p className="shortline">
                        {HTMLReactParser(data.description)}
                      </p>
                      <button
                        className="learnbtnleader"
                        onClick={() => {
                          viewleader(data.id);
                        }}
                      >
                        Learn More
                      </button>
                    </div>
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
        </div>
        /
      </div>
    </>
  );
};

export default Homeleader;
