import { Advertisement } from "../axios";

export const ViewAllAdvertisement = async (data) => {
  var ViewAllAdvertisement = await Advertisement.post(
    `/viewalladvertisement`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return ViewAllAdvertisement;
};
