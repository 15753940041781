/* eslint-disable no-undef */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../Assets/Css/Login.css";
import registerimg from "../Assets/images/login.png";
import registerimg1 from "../Assets/images/1 3.png";
import { BiLogoFacebook } from "react-icons/bi";
import { AiOutlineGoogle } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createMember, viewByphoneMember } from "../Api/member";
import { useNavigate } from "react-router-dom";
import Citylist from "../Pages/citylist.json";
import { firebase, auth } from "../database/firebase";
import { createNofification } from "../Api/nofification";
import Profilepic from "../Assets/images/profilepic.webp";
import Doneimg from "../Assets/images/done.png";
import Modal from "react-modal";
import Qrimg from "../Assets/images/QR.png";
import moment from "moment/moment";
import { ViewAllAdvertisement } from "../Api/advertisement";
import { viewallQrcode } from "../Api/qrcode";
import Select from "react-select";

const Register = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const navigate = useNavigate();
  const [clicked, setclicked] = useState("false");
  const [step, setstep] = useState("1");
  const [registerdata, setregisterdata] = useState({
    email: "",
    city: "",
    firstname: "",
    lastname: "",
    dob: "",
    occupation: "",
    state: "",
    address: "",
    profile: "",
    role: "",
    gender: "",
    area: "",
    kolom: "",
  });
  const [statelist, setstatelist] = useState([]);
  const [citylist, setcitylist] = useState([]);
  const [final, setfinal] = useState(null);
  const [otp, setotp] = useState("");
  const [show, setshow] = useState(false);
  const [verification, setverification] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [receipt, setreceipt] = useState(null);
  const [membertype, setmembertype] = useState("New");
  const [allqrdata, setallqrdata] = useState([]);
  const [occupationlist, setoccupationlist] = useState([]);
  const [newformData, setnewformData] = useState([]);

  const handleRegister = async (e) => {
    const { name, value } = e.target;
    setregisterdata((values) => ({ ...values, [name]: value }));
    if (name === "state") {
      var checkdata = await Citylist.states.filter((data) => {
        return data.state == e.target.value;
      });
      setcitylist(checkdata[0].districts);
      setregisterdata((values) => ({ ...values, [name]: value }));
    }
  };
  const registerbtn = async () => {
    if (registerdata.profile.length === 0) {
      toast.error("Please Upload Profile...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (registerdata.kolom.length === 0) {
      toast.error("Please Enter Kulam...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (registerdata.gender.length === 0) {
      toast.error("Please Select gender...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (registerdata.area.length === 0) {
      toast.error("Please Select Area...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (registerdata.occupation.length === 0) {
      toast.error("Please Select occupation...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (registerdata.state.length === 0) {
      toast.error("Please Select state...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (registerdata.city.length === 0) {
      toast.error("Please Select City...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (registerdata.address.length === 0) {
      toast.error("Please Enter Address...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      if (membertype === "New") {
        if (receipt == null) {
          toast.error("Please make payment and upload the receipt....", {
            autoClose: 2000,
            transition: Slide,
          });
        } else {
          setclicked(true);
          if (registerdata.phone === "" || registerdata.phone.length < 10)
            return;
          setclicked(true);
          var checkphone = await viewByphoneMember({
            phone: registerdata.phone,
          });
          if (checkphone.length == 0) {
            let verify = new firebase.auth.RecaptchaVerifier(
              "recaptcha-container"
            );
            auth
              .signInWithPhoneNumber(`+91${registerdata.phone}`, verify)
              .then((result) => {
                setfinal(result);
                toast.success("Otp Send your mobile number successfully...", {
                  autoClose: 2000,
                  transition: Slide,
                });
                setshow(true);
                setclicked(false);
              })
              .catch((err) => {
                setclicked(false);
                window.location.reload();
              });
          } else {
            toast.error(
              "Your number is already registered with another account....",
              {
                autoClose: 2000,
                transition: Slide,
              }
            );
            setclicked(false);
          }
        }
      } else {
        setclicked(true);
        if (registerdata.phone === "" || registerdata.phone.length < 10) return;
        setclicked(true);
        var checkphone = await viewByphoneMember({
          phone: registerdata.phone,
        });
        if (checkphone.length == 0) {
          let verify = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container"
          );
          auth
            .signInWithPhoneNumber(`+91${registerdata.phone}`, verify)
            .then((result) => {
              setfinal(result);
              toast.success("Otp Send your mobile number successfully...", {
                autoClose: 2000,
                transition: Slide,
              });
              setshow(true);
              setclicked(false);
            })
            .catch((err) => {
              setclicked(false);
              window.location.reload();
            });
        } else {
          toast.error(
            "Your number is already registered with another account....",
            {
              autoClose: 2000,
              transition: Slide,
            }
          );
          setclicked(false);
        }
      }
    }
  };
  const firststepbtn = async () => {
    if (registerdata.firstname.length === 0) {
      toast.error("Please enter First Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (registerdata.lastname.length === 0) {
      toast.error("Please enter Last Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (registerdata.dob.length === 0) {
      toast.error("Please enter Date Of Birth...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (registerdata.email.length === 0) {
      toast.error("Please enter Email...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (registerdata.phone.length === 0) {
      toast.error("Please enter Phone Number...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setstep("2");
      setTimeout(() => {
        document.getElementById("kolam").value = "";
        // document.getElementById("occupationinput").value = "";
      }, 1000);
    }
  };
  useEffect(() => {
    getstatedata();
  }, []);
  const getstatedata = async () => {
    setstatelist(Citylist.states);
    var alldata = await viewallQrcode();
    setallqrdata(alldata);
    var newobj = [
      {
        value: "Manufacturers & Merchandise traders",
        label: "Manufacturers & Merchandise traders",
      },
      {
        value: "Agricultural",
        label: "Agricultural",
      },
      {
        value: "Alcohol/Beverages",
        label: "Alcohol/Beverages",
      },
      {
        value: "Animal Feed & Poultry",
        label: "Animal Feed & Poultry",
      },
      {
        value: "Automobiles",
        label: "Automobiles",
      },
      {
        value: "Builders",
        label: "Builders",
      },
      {
        value: "Hardware",
        label: "Hardware",
      },
      {
        value: "Hospital",
        label: "Hospital",
      },
      {
        value: "Clinic/Lab",
        label: "Clinic/Lab",
      },
      {
        value: "Chemicals",
        label: "Chemicals",
      },
      {
        value: "Computer Peripherals",
        label: "Computer Peripherals",
      },
      {
        value: "Consumer Electronics",
        label: "Consumer Electronics",
      },
      {
        value: "Cosmetics",
        label: "Cosmetics",
      },
      {
        value: "Electrical Equipment",
        label: "Electrical Equipment",
      },
      {
        value: "Food Products & Bakery",
        label: "Food Products & Bakery",
      },

      {
        value: "Furniture",
        label: "Furniture",
      },
      {
        value: "Handicrafts",
        label: "Handicrafts",
      },
      {
        value: "Industrial Electricals",
        label: "Industrial Electricals",
      },
      {
        value: "Pharmaceuticals",
        label: "Pharmaceuticals",
      },
      {
        value: "Advertising Agencies",
        label: "Advertising Agencies",
      },

      {
        value: "Ayurvedic",
        label: "Ayurvedic",
      },
    ];
    setoccupationlist(newobj);
  };
  const ValidateOtp = async () => {
    if (otp === null || final === null) return;
    setclicked(true);
    final
      .confirm(otp)
      .then(async (result) => {
        toast.success("Otp Verified successfully...", {
          autoClose: 1000,
          transition: Slide,
        });
        registerdata["status"] = "false";
        registerdata["receipt"] = receipt;
        registerdata["role"] = "Life Member";

        var nofidata = {
          content: `${registerdata.firstname} ${registerdata.lastname} (${registerdata.phone}) registered a new member account.`,
          status: "true",
          validto: moment().add(7, "days").format("YYYY-MM-DD"),
        };
        var createdata = await createMember(registerdata);
        if (createdata.message === "SUCCESS") {
          toast.success("Registration request made successfully....", {
            autoClose: 2000,
            transition: Slide,
          });
          setverification(true);
          createNofification(nofidata);
          setTimeout(() => {
            window.location.replace("/login");
          }, 5000);
        } else {
          toast.error(createdata.message, {
            autoClose: 2000,
            transition: Slide,
          });
          setclicked(false);
        }
      })
      .catch((err) => {
        setclicked(false);
        alert("Wrong code");
      });
  };
  const geturl = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Image uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setregisterdata((values) => ({ ...values, profile: imgurl1 }));
      }
    }
  };
  const geturlnew = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Receipt Upload Successfully...", {
          autoClose: 5000,
          transition: Slide,
        });
        setreceipt(imgurl1);
      }
    }
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const [addata, setaddata] = useState([]);
  useEffect(() => {
    getmydata();
  }, []);
  const getmydata = async () => {
    var alladvertisement = await ViewAllAdvertisement();
    setaddata(alladvertisement);
  };
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentBannerIndex((prevIndex) =>
        prevIndex === addata.length - 1 ? 0 : prevIndex + 1
      );
    }, 10000); // 10000 milliseconds = 10 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [addata]);
  const hanglechangeNew = async (value) => {
    setregisterdata((values) => ({ ...values, occupation: value.value }));
  };
  const [searchvalue, setsearchvalue] = useState("");
  const handleInputChange = (value, e) => {
    setsearchvalue(value);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      var otherSkillsOptionsList1 = [
        ...occupationlist,
        {
          value: capitalizeText(searchvalue),
          label: capitalizeText(searchvalue),
        },
      ];
      var ColorVariation2 = [
        {
          value: capitalizeText(searchvalue),
          label: capitalizeText(searchvalue),
        },
      ];
      setnewformData(ColorVariation2);
      setoccupationlist(otherSkillsOptionsList1);
      setsearchvalue("");
    }
  };
  const capitalizeText = (text) => {
    return text
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const customNoOptionsMessage = ({ innerProps }) => (
    <div
      {...innerProps}
      onClick={() => {
        setregisterdata((values) => ({
          ...values,
          occupation: capitalizeText(searchvalue),
        }));
        var otherSkillsOptionsList1 = [
          ...occupationlist,
          {
            value: capitalizeText(searchvalue),
            label: capitalizeText(searchvalue),
          },
        ];
        var ColorVariation2 = [
          {
            value: capitalizeText(searchvalue),
            label: capitalizeText(searchvalue),
          },
        ];
        setnewformData(ColorVariation2);
        setoccupationlist(otherSkillsOptionsList1);
        setsearchvalue("");
      }}
    >
      <p className="addnewtag">Add New</p>
    </div>
  );
  return (
    <>
      <div className="login">
        <div className="login1">
          <img src={registerimg} alt="" />
        </div>
        <div className="login2">
          <div className="logo2">
            <img
              src={registerimg1}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.location.replace("/");
              }}
            />
            <p>Thenmaavatta Okkaligar (Kaappu) </p>
            <p>Mahajana Nala Sangam, Kovai</p>
          </div>
          {verification === true ? (
            <>
              {" "}
              <img src={Doneimg} alt="" className="verifyimg" />
              <p className="suggtag">Registration request made successfully</p>
            </>
          ) : (
            <div className="login-title">
              <h1>Raise a Registration Request !</h1>
              {step === "1" ? (
                <div className="inputss">
                  <input
                    type="text"
                    placeholder="First Name"
                    name="firstname"
                    onChange={handleRegister}
                    defaultValue={registerdata.firstname}
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="lastname"
                    onChange={handleRegister}
                    defaultValue={registerdata.lastname}
                  />
                  <input
                    type="date"
                    placeholder="Date of Birth"
                    name="dob"
                    onChange={handleRegister}
                    defaultValue={registerdata.dob}
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    onChange={handleRegister}
                    defaultValue={registerdata.email}
                  />
                  <input
                    type="number"
                    placeholder="Phone Number"
                    name="phone"
                    onChange={handleRegister}
                    defaultValue={registerdata.phone}
                  />
                </div>
              ) : (
                <div className="inputss" style={{ display: show && "none" }}>
                  {registerdata.profile.length == 0 ? (
                    <img src={Profilepic} alt="" className="profilepicimg" />
                  ) : (
                    <img
                      src={registerdata.profile}
                      alt=""
                      className="profilepicimg"
                    />
                  )}

                  <input
                    type="file"
                    placeholder="file"
                    name="phone"
                    onChange={geturl}
                  />

                  {/* <select
                    name="role"
                    onChange={handleRegister}
                    defaultValue={registerdata.role}
                  >
                    <option value="">Select Role</option>
                    <option value="Member">Member</option>
                    <option value="Life Member">Life Member</option>
                  </select> */}

                  <select
                    name="gender"
                    onChange={handleRegister}
                    defaultValue={registerdata.role}
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>

                  <select
                    name="area"
                    onChange={handleRegister}
                    defaultValue={registerdata.role}
                  >
                    <option value="">Select Area</option>
                    <option value="தடாகம்ரோடு">தடாகம்ரோடு</option>
                    <option value="மருதமலைரோடு">மருதமலைரோடு</option>
                    <option value="மேட்டுப்பாளையம்ரோடு">
                      மேட்டுப்பாளையம்ரோடு
                    </option>
                    <option value="சத்திரோடு">சத்திரோடு</option>
                    <option value="அவினாசிரோடு">அவினாசிரோடு</option>
                    <option value="திருச்சிரோடு">திருச்சிரோடு</option>
                    <option value="பொள்ளாச்சிரோடு">பொள்ளாச்சிரோடு</option>
                    <option value="Others">Others</option>
                  </select>

                  {/* <input
                    type="text"
                    placeholder="Occupation"
                    name="occupation"
                    id="occupationinput"
                    onChange={handleRegister}
                    defaultValue={registerdata.occupation}
                  /> */}

                  <Select
                    // isMulti
                    name="colors"
                    options={occupationlist}
                    className="newselect"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    onChange={hanglechangeNew}
                    onInputChange={handleInputChange}
                    // inputValue={searchvalue}
                    // value={newformData}
                    components={{ NoOptionsMessage: customNoOptionsMessage }}
                    onKeyDown={handleKeyDown}
                  />
                  <input
                    type="text"
                    placeholder="Kulam"
                    name="kolom"
                    id="kolam"
                    onChange={handleRegister}
                    defaultValue={registerdata.kolom}
                  />
                  {/* <select
                    name="occupation"
                    onChange={handleRegister}
                    defaultValue={registerdata.occupation}
                  >
                    <option value="">Select Occupation</option>
                    <option value="Manufacturers & Merchandise traders">
                      Manufacturers & Merchandise traders
                    </option>
                    <option value="Agricultural">Agricultural</option>
                    <option value="Alcohol/Beverages">Alcohol/Beverages</option>
                    <option value="Animal Feed & Poultry">
                      Animal Feed & Poultry
                    </option>
                    <option value="Automobiles">Automobiles</option>
                    <option value="Builders">Builders</option>
                    <option value="Hardware">Hardware</option>
                    <option value="Hospital">Hospital</option>
                    <option value="Clinic/Lab">Clinic/Lab</option>
                    <option value="Chemicals">Chemicals</option>
                    <option value="Computer Peripherals">
                      Computer Peripherals
                    </option>
                    <option value="Consumer Electronics">
                      Consumer Electronics
                    </option>
                    <option value="Cosmetics">Cosmetics</option>
                    <option value="Electrical Equipment">
                      Electrical Equipment
                    </option>
                    <option value="Food Products & Bakery">
                      {" "}
                      Food Products & Bakery
                    </option>
                    <option value="Furniture">Furniture</option>
                    <option value="Gifts & Novelty Items">
                      Gifts & Novelty Items
                    </option>
                    <option value="Handicrafts">Handicrafts</option>
                    <option value="Industrial Electricals">
                      Industrial Electricals
                    </option>
                    <option value="Music Systems">Music Systems</option>
                    <option value="Pharmaceuticals">Pharmaceuticals</option>
                    <option value="Printing">Printing</option>
                    <option value="PVC&Rubber products">
                      PVC&Rubber products
                    </option>
                    <option value="Stationaries">Stationaries</option>
                    <option value="Retail Shops">Retail Shops</option>
                    <option value="Textiles">Textiles</option>
                    <option value="Workshop">Workshop</option>
                    <option value="Advertising Agencies">
                      {" "}
                      Advertising Agencies
                    </option>
                    <option value="Ayurvedic">Ayurvedic</option>
                    <option value="Banks">Banks</option>
                    <option value="Building Contractors">
                      Building Contractors
                    </option>
                    <option value="Business Consultants">
                      Business Consultants
                    </option>
                    <option value="Computer Maintenance">
                      Computer Maintenance
                    </option>
                    <option value="Education Consultant">
                      Education Consultant
                    </option>
                    <option value="Courier Services">Courier Services</option>
                    <option value="Drilling Rig Contractors">
                      Drilling Rig Contractors
                    </option>
                    <option value="Electrical Contractors">
                      Electrical Contractors
                    </option>{" "}
                    <option value="Event Organisors">Event Organisors</option>
                    <option value="Finance">Finance</option>
                    <option value="Hotels/Holiday Resorts">
                      Hotels/Holiday Resorts
                    </option>
                    <option value="Manpower Recruitment">
                      Manpower Recruitment
                    </option>
                    <option value="NGO's">NGO's</option>
                    <option value="Real Estate">Real Estate</option>
                    <option value="Software/Web Developers">
                      Software/Web Developers
                    </option>
                    <option value="Tansporters/C&F Agents">
                      Tansporters/C&F Agents
                    </option>
                    <option value="Training Services">Training Services</option>
                    <option value="Travel Agency">Travel Agency</option>
                  </select> */}
                  <select
                    name="state"
                    onChange={handleRegister}
                    defaultValue={registerdata.state}
                  >
                    <option value="">State</option>
                    {statelist.length !== 0
                      ? statelist.map((data, index) => (
                          <option value={data.state} key={index}>
                            {data.state}
                          </option>
                        ))
                      : null}
                  </select>
                  <select
                    name="city"
                    onChange={handleRegister}
                    defaultValue={registerdata.city}
                  >
                    <option value="">City</option>
                    {citylist.length !== 0
                      ? citylist.map((data, index) => (
                          <option value={data} key={index}>
                            {data}
                          </option>
                        ))
                      : null}
                  </select>
                  <textarea
                    type="text"
                    placeholder="Address"
                    defaultValue={registerdata.address}
                    name="address"
                    onChange={handleRegister}
                  />
                  <div className="flexcheck">
                    <input
                      type="checkbox"
                      id="New"
                      name="New"
                      value="New"
                      className="checkboxinput"
                      onClick={() => {
                        setmembertype("New");
                      }}
                      checked={membertype === "New"}
                    />
                    <label
                      for="New"
                      onClick={() => {
                        setmembertype("New");
                      }}
                    >
                      New Member
                    </label>
                  </div>
                  <div className="flexcheck">
                    <input
                      type="checkbox"
                      id="Existing"
                      name="Existing"
                      value="Existing"
                      className="checkboxinput"
                      onClick={() => {
                        setmembertype("Existing");
                      }}
                      checked={membertype === "Existing"}
                    />
                    <label
                      for="Existing"
                      onClick={() => {
                        setmembertype("Existing");
                      }}
                    >
                      {" "}
                      Existing Member
                    </label>
                  </div>
                  {membertype === "Existing" ? null : (
                    <button className="makepayment" onClick={openModal}>
                      Make Payment
                    </button>
                  )}

                  <div id="recaptcha-container" className="forget"></div>
                </div>
              )}

              {step === "1" ? (
                <button className="loginbtn" onClick={firststepbtn}>
                  Next
                </button>
              ) : clicked === true ? (
                <button
                  className="loginbtn"
                  style={{ display: show && "none" }}
                >
                  Please Wait...
                </button>
              ) : (
                <div className="buttongrp" style={{ display: show && "none" }}>
                  <button
                    className="loginbtn"
                    onClick={() => {
                      setstep("1");
                    }}
                  >
                    Previous
                  </button>

                  {clicked === true ? (
                    <button className="loginbtn">Please wait...</button>
                  ) : (
                    <>
                      <button className="loginbtn" onClick={registerbtn}>
                        Send OTP
                      </button>
                    </>
                  )}
                </div>
              )}
              <div className="inputss" style={{ display: !show && "none" }}>
                <input
                  type="text"
                  placeholder={"Enter your OTP"}
                  onChange={(e) => {
                    setotp(e.target.value);
                  }}
                ></input>
                {clicked === true ? (
                  <button className="loginbtn">Please wait...</button>
                ) : (
                  <>
                    <button className="loginbtn" onClick={ValidateOtp}>
                      Verify
                    </button>
                    {/* <br />
                  <button className="loginbtn" onClick={loginbtn}>
                    Resend
                  </button> */}
                  </>
                )}
              </div>
              {/* <div className="iconss">
              <BiLogoFacebook className="icon11" />
              <AiOutlineGoogle className="icon22" />
              <BsTwitter className="icon33" />
              <BsYoutube className="icon44" />
            </div> */}
              <h5
                className="donttag"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Already have an account ? <a>Login</a>
              </h5>

              <div className="loginadd">
                {addata.map((image, index) => (
                  <div
                    key={index}
                    style={{
                      display: index === currentBannerIndex ? "block" : "none",
                    }}
                    className="advertisement-banner"
                  >
                    <img
                      src={image.image}
                      alt={`Banner ${index + 1}`}
                      className="loginimg"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <ToastContainer />
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        className="newsmodel"
      >
        <div>
          <div>
            <button className="closebtn" onClick={closeModal}>
              ✕
            </button>
            <h3 className="modelhead">Make Payment</h3>
          </div>
          <div className="divider mt-2"></div>
          {allqrdata.length !== 0 ? (
            <div className="paymentdiv">
              <img src={allqrdata[0].image} alt="" className="qrimg" />
            </div>
          ) : null}

          <div>
            <h4>Upload Receipt</h4>
            <input type="file" className="receiptinput" onChange={geturlnew} />
          </div>
          <div className="divider mt-2"></div>
          <div className="modal-action">
            {clicked === true ? (
              <button className="savebtn">Please Wait...</button>
            ) : (
              <button className="savebtn" onClick={closeModal}>
                Save
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Register;
