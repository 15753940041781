/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import HTMLReactParser from "html-react-parser";
import "../../Assets/Css/company.css";
import { viewByidcompany } from "../../Api/company";

const Companydetails = () => {
  const [singledata, setsingledata] = useState([]);
  var id = 1;
  useEffect(() => {
    getsingledata();
  }, [id]);
  const getsingledata = async () => {
    var companydata = await viewByidcompany({ id: id });
    setsingledata(companydata);
  };
  return (
    <>
      {singledata.length !== 0 ? (
        <div className="company-details">
          <div className="company-details1">
            <div className="company-details2">
              <div className="company-det-images">
                <img
                  src={singledata[0].cover}
                  alt=""
                  className="companycover"
                />
              </div>
              <div className="com-contents">
                <h1>{singledata[0].name}</h1>
                <p>{singledata[0].city}</p>
              </div>
            </div>
            <div className="company-contents">
              <p>{HTMLReactParser(singledata[0].description)}</p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Companydetails;
