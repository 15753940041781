import React from "react";

import Homemission from "../Component/Home/Homemission";
import Homeswipper from "../Component/Home/Homeswipper";
import Homehistory from "../Component/Home/Homehistory";
import Homeleader from "../Component/Home/Homeleader";
import Homenews from "../Component/Home/Homenews";
import Navbar from "../Component/Navbar";
import Footer from "../Component/Footer";
const Home = () => {
  sessionStorage.removeItem("newsid");

  return (
    <>
      <Navbar />
      <Homeswipper />
      <Homemission />
      <Homehistory />
      <Homeleader />
      <Homenews />
      <Footer />
    </>
  );
};

export default Home;
