import React, { useEffect, useState } from "react";
import "../../Assets/Css/homemission.css";
import mission_image from "../../Assets/images/WhatsApp Image 2023-10-09 at 16.35 1.jpg";
import { ViewAllAdvertisement } from "../../Api/advertisement";

const Homemission = () => {
  const [addata, setaddata] = useState([]);
  useEffect(() => {
    getmydata();
  }, []);
  const getmydata = async () => {
    var alladvertisement = await ViewAllAdvertisement();
    setaddata(alladvertisement);
  };
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentBannerIndex((prevIndex) =>
        prevIndex === addata.length - 1 ? 0 : prevIndex + 1
      );
    }, 10000); // 10000 milliseconds = 10 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [addata]);
  return (
    <>
      <div className="home-mission">
        <div className="home-mission1">
          <div className="mission-image">
            <img src={mission_image} alt="" />
          </div>
          <div className="mission-content">
            <div className="mission-title">
              <h1>WHO WE ARE</h1>
              <p>
                Vokkaliga is a generic term applied to several castes whose main
                occupation has been agriculture. Earlier eighteen groups called
                themselves Vokkaligas. In due course, this community only has
                been named called as Vollakigas. Edgur Thurston and Rangachary
                support this view. Gazetteers, Manuals, Census reports, The
                Tamilnadu Govt. Backward Class Commission reports and other
                Government sources only one community as Vokkaliga community.
              </p>
            </div>
            <div className="our-mission">
              <div className="mission1">
                <h1>Our Mission</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore.
                </p>
              </div>
              <div className="mission1" name="mission">
                <h1>Our Vision</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="loginadd">
        {addata.map((image, index) => (
          <div
            key={index}
            style={{
              display: index === currentBannerIndex ? "block" : "none",
            }}
            className="advertisement-banner"
          >
            <img
              src={image.image}
              alt={`Banner ${index + 1}`}
              className="homeimg"
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default Homemission;
