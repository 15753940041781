/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import HTMLReactParser from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../../Assets/Css/homenews.css";
import { viewallNews } from "../../Api/news";
import moment from "moment/moment";
import { ViewAllAdvertisement } from "../../Api/advertisement";

const Homenews = () => {
  const [allnews, setallnews] = useState([]);
  const [addata, setaddata] = useState([]);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  useEffect(() => {
    getAlldata();
  }, []);
  const getAlldata = async () => {
    var alldata = await viewallNews();
    setallnews(alldata);
    var alladvertisement = await ViewAllAdvertisement();
    setaddata(alladvertisement);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentBannerIndex((prevIndex) =>
        prevIndex === addata.length - 1 ? 0 : prevIndex + 1
      );
    }, 10000); // 10000 milliseconds = 10 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [addata]);
  return (
    <>
      {/* {allnews.length !== 0 ? (
        <>
          {" "}
          <div className="home-news">
            <div className="home-news1">
              <div className="home-news2">
                <h1>NEWS</h1>
              </div>
            </div>
          </div>
          <div className="home-news-slider">
            <div className="home-news-slider1">
              <Swiper
                spaceBetween={30}
                slidesPerView={1}
                loop={true}
                autoplay={true}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                }}
              >
                {allnews.length !== 0
                  ? allnews.map((data, index) => (
                      <SwiperSlide className="home1-news" key={index}>
                        <div className="newsslider1">
                          <div className="imm">
                            <img src={data.image} alt="" />
                          </div>
                          <div className="news-scroll">
                            <div className="news-date">
                              <h1>{moment(data.createdAt).format("DD")}</h1>
                              <div className="news-month">
                                <p>{moment(data.createdAt).format("MMMM")}</p>
                                <p>{moment(data.createdAt).format("YYYY")}</p>
                              </div>
                            </div>
                            <div className="news-contenthome">
                              <h1>{data.title}</h1>
                              <p className="shortlinenew">
                                {HTMLReactParser(data.decription)}
                              </p>
                              <button
                                className="learnbtnleader"
                                onClick={() => {
                                  window.location.replace(`/news`);
                                  sessionStorage.setItem("newsid", data.id);
                                }}
                              >
                                Read More
                              </button>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))
                  : null}
              </Swiper>
            </div>
          </div>
        </>
      ) : null} */}
      <div className="adhome">
        {addata.map((image, index) => (
          <div
            key={index}
            style={{
              display: index === currentBannerIndex ? "block" : "none",
            }}
            className="advertisement-banner"
          >
            <img
              src={image.image}
              alt={`Banner ${index + 1}`}
              className="homeimg"
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default Homenews;
