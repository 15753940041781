import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Register from "./Component/Register";
import Login from "./Component/Login";
import Home from "./Pages/Home";
import Gallery from "./Pages/Gallery";
import Myfamily from "./Pages/Myfamily";
import Myleaders from "./Pages/Myleaders";
import Mymeeting from "./Pages/Mymeeting";
import Mynews from "./Pages/Mynews";
import Myprofile from "./Pages/Myprofile";
import Office from "./Pages/Office";
import Events from "./Pages/Events";
import Myevent from "./Pages/Myevent";
import Mytrade from "./Pages/Mytrade";
import Mymembers from "./Pages/Mymembers";
import Maruthamalai from "./Pages/Maruthamalai";
import Marriges from "./Pages/Marriges";
import Project from "./Pages/Project";
import Fund from "./Pages/Fund";
import Memberdetails from "./Pages/Memberdetails";
import Test from "./Pages/Test";
import History from "./Pages/History";
import Company from "./Pages/Company";
import Familydetails from "./Pages/Familydetails";
import Kulamlist from "./Pages/Kulamlist";
import Helpline from "./Pages/Helpline";
import Companydetails from "./Pages/Companydetails";
import Kulamview from "./Pages/Kulamview";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Test />
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/myfamily" element={<Myfamily />} />
          <Route path="/leaders" element={<Myleaders />} />
          <Route path="/meeting" element={<Mymeeting />} />
          <Route path="/news" element={<Mynews />} />
          <Route path="/myprofile" element={<Myprofile />} />
          <Route path="/office" element={<Office />} />
          <Route path="/allevents" element={<Events />} />
          <Route path="/event" element={<Myevent />} />
          <Route path="/trade" element={<Mytrade />} />
          <Route path="/members" element={<Mymembers />} />
          <Route path="/sangam" element={<Maruthamalai />} />
          <Route path="/familyfunction" element={<Marriges />} />
          <Route path="/fund" element={<Fund />} />
          <Route path="/project" element={<Project />} />
          <Route path="/memberdetails" element={<Memberdetails />} />
          <Route path="/history" element={<History />} />
          <Route path="/company" element={<Company />} />
          <Route path="/familydetails" element={<Familydetails />} />
          <Route path="/kulam" element={<Kulamlist />} />
          <Route path="/helpline" element={<Helpline />} />
          <Route path="/companydetails" element={<Companydetails />} />
          <Route path="/kulamview" element={<Kulamview />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
