/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../Assets/Css/news.css";
import { viewallEvent } from "../../Api/event";
import HTMLReactParser from "html-react-parser";
import moment from "moment";

const Myevent = () => {
  const [allnews, setallnews] = useState([]);
  const [singlenews, setsinglenews] = useState([]);
  const [newsid, setnewsid] = useState([]);

  var id = sessionStorage.getItem("eventid");
  useEffect(() => {
    getAlldata();
  }, [id]);
  const getAlldata = async () => {
    if (id === null) {
      var alldata = await viewallEvent();
      if (alldata.length !== 0) {
        setallnews(alldata);
        setsinglenews([alldata[0]]);
        setnewsid(alldata[0].id);
      }
    } else {
      var alldatanew = await viewallEvent();
      if (alldatanew.length !== 0) {
        var checkdata = await alldatanew.filter((data) => {
          return data.id == id;
        });
        setallnews(alldatanew);
        setsinglenews([checkdata[0]]);
        setnewsid(id);
      }
    }
  };
  return (
    <>
      <div className="fulldiv">
        <div className="newscontent">
          {singlenews.length !== 0 ? (
            <div className="newsimage">
              <img src={singlenews[0].image} alt="" />
              <div className="newscont">
                <h3 className="newstitle">{singlenews[0].title}</h3>
                <p>
                  {" "}
                  {moment(singlenews[0].createdAt).format("MMMM DD, YYYY")}
                </p>
                <p>{HTMLReactParser(singlenews[0].description)}</p>
              </div>
            </div>
          ) : (
            <div className="newsimage"></div>
          )}

          <div className="newscroll">
            <h1>Recent posts</h1>
            <div className="picscroll">
              {allnews.length !== 0
                ? allnews.map((data, index) => (
                    <div
                      className={
                        newsid == data.id
                          ? "newsimgscroll activenewsscroll"
                          : "newsimgscroll"
                      }
                      key={index}
                      onClick={() => {
                        setnewsid(data.id);
                        setsinglenews([data]);
                        sessionStorage.removeItem("eventid");
                      }}
                    >
                      <img src={data.image} alt="" />
                      <div className="newsdetail">
                        <h3>{data.title}</h3>
                        <p>{moment(data.createdAt).format("MMMM DD, YYYY")}</p>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Myevent;
