import { Qrcode } from "../axios";

export const viewallQrcode = async (data) => {
  var viewallQrcode = await Qrcode.post(`/viewallqrcode`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallQrcode;
};

export const createQrcode = async (data) => {
  var createQrcode = await Qrcode.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createQrcode;
};

export const viewByidQrcode = async (data) => {
  var viewByidQrcode = await Qrcode.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidQrcode;
};

export const destroyQrcode = async (data) => {
  var destroyQrcode = await Qrcode.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyQrcode;
};

export const updateQrcode = async (data) => {
  var updateQrcode = await Qrcode.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateQrcode;
};
