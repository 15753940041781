import axios from "axios";

export const Leaders = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/leaders`,
});

export const News = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/news`,
});

export const Gallery = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/gallery`,
});

export const Contactus = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/contactus`,
});

export const Family = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/family`,
});

export const Member = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/member`,
});

export const Metting = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/metting`,
});

export const Event = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/event`,
});

export const Officebearers = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/officebearers`,
});

export const Maruthamalaioffice = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/maruthamalaioffice`,
});

export const Familyfinction = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/familyfinction`,
});

export const Educationfund = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/educationfund`,
});

export const Educationfunddonor = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/educationfunddonor`,
});

export const Nofification = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/nofification`,
});

export const Company = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/company`,
});

export const Kulam = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/kulam`,
});

export const Kothiram = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/kothiram`,
});

export const Advertisement = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/advertisement`,
});

export const Qrcode = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/qrcode`,
});

export const Cover = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/cover`,
});
