import React, { useEffect, useState } from "react";
import { viewallKulam } from "../../Api/kulam";
import HTMLReactParser from "html-react-parser";

const Kulamlist = () => {
  const [alldata, setalldata] = useState([]);
  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    var alldata = await viewallKulam();
    if (alldata.length !== 0) {
      setalldata(alldata);
    }
  };
  const viewbtn = (id) => {
    sessionStorage.setItem("kulamid", id);
    window.location.replace("/kulamview");
  };
  return (
    <>
      <div className="member-head">
        <div className="member-head1">
          <h1>List of Kulam & it’s history</h1>
        </div>
      </div>
      <div className="kulam-cards">
        {alldata.length !== 0
          ? alldata.map((data, index) => (
              <div className="member-card1" key={index}>
                <div className="member-box1">
                  <div className="member-img">
                    <img src={data.cover} alt="" />
                  </div>
                  <div className="member-img-con">
                    <h2>{data.name}</h2>
                    <p className="shortline">
                      {HTMLReactParser(data.description)}
                    </p>
                    <button
                      className="learnbtnleadernew"
                      onClick={() => {
                        viewbtn(data.id);
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
    </>
  );
};

export default Kulamlist;
