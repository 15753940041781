import React from "react";
import Navbar from "../Component/Navbar";
import Meeting from "../Component/Meeting/Meeting";

import Footer from "../Component/Footer";

const Mymeeting = () => {
  return (
    <>
      <Navbar />
      <Meeting />

      <Footer />
    </>
  );
};

export default Mymeeting;
