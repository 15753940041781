/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../Assets/Css/Profile.css";
import { updateMember, viewByidMember } from "../../Api/member";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { firebase } from "../../database/firebase";
import Modal from "react-modal";
import { BsFillPencilFill } from "react-icons/bs";
import {
  createFamily,
  destroyFamily,
  updateFamily,
  viewBymemberFamily,
} from "../../Api/family";
import { FaTrash } from "react-icons/fa";

import { createNofification } from "../../Api/nofification";
import Citylist from "../../Pages/citylist.json";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import "../../Assets/Css/style.css";
import HTMLReactParser from "html-react-parser";
import { createcompany, updateCompany } from "../../Api/company";

const Profile = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const [profiledata, setprofiledata] = useState([]);
  const [formdatadata, setformdatadata] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    role: "",
    profile: "",
    description: "",
    member: "",
    dob: "",
    occupation: "",
    state: "",
    city: "",
    address: "",
    cast: "",
    kolom: "",
    kothiram: "",
    jathagam: "",
    gender: "",
  });
  const [companydata, setcompanydata] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    state: "",
    city: "",
    pincode: "",
    description: "",
    cover: "",
    gallery: "",
  });
  const [clicked, setclicked] = useState(false);
  const [updatecompany, setupdatecompany] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpennew, setmodalIsOpennew] = React.useState(false);
  const [modalIsOpennewcompany, setmodalIsOpennewcompany] =
    React.useState(false);
  const [allfamily, setallfamily] = useState([]);
  const [statelist, setstatelist] = useState([]);
  const [citylist, setcitylist] = useState([]);
  const [updatedid, setupdatedid] = useState(null);

  var memberid =
    localStorage.getItem("memberid") || sessionStorage.getItem("memberid");
  useEffect(() => {
    getAlldata();
  }, [memberid]);
  const getAlldata = async () => {
    var profiledata = await viewByidMember({ id: memberid });
    if (profiledata.length !== 0) {
      setprofiledata(profiledata);
      setmemberdata({
        firstname: profiledata[0].firstname,
        lastname: profiledata[0].lastname,
        email: profiledata[0].email,
        phone: profiledata[0].phone,
        dob: profiledata[0].dob,
        occupation: profiledata[0].occupation,
        profile: profiledata[0].profile,
        state: profiledata[0].state,
        city: profiledata[0].city,
        address: profiledata[0].address,
        role: profiledata[0].role,
        cast: profiledata[0].cast,
        kolom: profiledata[0].kolom,
        kothiram: profiledata[0].kothiram,
        jathagam: profiledata[0].jathagam,
        gender: profiledata[0].gender,
      });
    }
    if (memberid !== null) {
      var alldata = await viewBymemberFamily({ id: memberid });
      if (alldata.length !== 0) {
        setallfamily(alldata);
      }
    }
    setstatelist(Citylist.states);
  };
  const changeformdta = async (e) => {
    const { name, value } = e.target;
    if (name === "state") {
      var checkdata = await Citylist.states.filter((data) => {
        return data.state == e.target.value;
      });
      setcitylist(checkdata[0].districts);
      setformdatadata((values) => ({ ...values, [name]: value }));
    }
    setformdatadata((values) => ({ ...values, [name]: value }));
  };
  const changecompanydata = async (e) => {
    const { name, value } = e.target;
    if (name === "state") {
      var checkdata = await Citylist.states.filter((data) => {
        return data.state == e.target.value;
      });
      setcitylist(checkdata[0].districts);
      setformdatadata((values) => ({ ...values, [name]: value }));
    }
    setcompanydata((values) => ({ ...values, [name]: value }));
  };
  function openModal() {
    setIsOpen(true);
    setupdatedid(null);
    setformdatadata({
      name: "",
      email: "",
      password: "",
      phone: "",
      role: "",
      profile: "",
      description: "",
      member: "",
      dob: "",
      occupation: "",
      state: "",
      city: "",
      address: "",
      cast: "",
      kolom: "",
      kothiram: "",
      jathagam: "",
      gender: "",
    });
  }
  function closeModal() {
    setIsOpen(false);
  }
  function closeModalnew() {
    setmodalIsOpennew(false);
  }
  function closecompanymodel() {
    setmodalIsOpennewcompany(false);
  }
  const geturl = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Image uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdatadata((values) => ({ ...values, jathagam: imgurl1 }));
      }
    }
  };
  const getprofile = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Image uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdatadata((values) => ({ ...values, profile: imgurl1 }));
      }
    }
  };
  const geturlnew = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Image uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setmemberdata((values) => ({ ...values, jathagam: imgurl1 }));
      }
    }
  };
  const geturlcover = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Image uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setcompanydata((values) => ({ ...values, cover: imgurl1 }));
      }
    }
  };
  const save_btn = async () => {
    if (formdatadata.name.length === 0) {
      toast.error("Please Enter Name...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (formdatadata.profile.length === 0) {
      toast.error("Please Upload Profile...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      formdatadata["member"] =
        localStorage.getItem("memberid") || sessionStorage.getItem("memberid");
      var nofidata = {
        content: `${profiledata[0].firstname} ${profiledata[0].lastname} added a new family member.`,
        status: "true",
        validto: moment().add(7, "days").format("YYYY-MM-DD"),
      };
      var createfamily = await createFamily(formdatadata);
      if (createfamily.message === "SUCCESS") {
        toast.success("Family Registed Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        createNofification(nofidata);
        setclicked(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
  };
  const updatebtn = async () => {
    if (formdatadata.name.length === 0) {
      toast.error("Please Enter Name...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (formdatadata.profile.length === 0) {
      toast.error("Please Upload Profile...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      formdatadata["id"] = updatedid;
      var updatedata = await updateFamily(formdatadata);
      if (updatedata === "Updated Successfully") {
        var nofidata = {
          content: `${profiledata[0].firstname} ${profiledata[0].lastname} Updated family member details.`,
          status: "true",
          validto: moment().add(7, "days").format("YYYY-MM-DD"),
        };
        createNofification(nofidata);
        setclicked(false);
        toast.success("Family updated Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
  };
  const [memberdata, setmemberdata] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    dob: "",
    occupation: "",
    profile: "",
    state: "",
    city: "",
    address: "",
    role: "",
    cast: "",
    kolom: "",
    kothiram: "",
    jathagam: "",
    gender: "",
  });
  const changeformdata = async (e) => {
    const { name, value } = e.target;
    if (name === "state") {
      var checkdata = await Citylist.states.filter((data) => {
        return data.state == e.target.value;
      });
      setcitylist(checkdata[0].districts);
      setmemberdata((values) => ({ ...values, [name]: value }));
    }
    setmemberdata((values) => ({ ...values, [name]: value }));
  };
  const savemember = async () => {
    if (memberdata.firstname.length === 0) {
      toast.error("Please Enter First Name...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (memberdata.lastname.length === 0) {
      toast.error("Please Enter Last Name...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (memberdata.email.length === 0) {
      toast.error("Please Enter Email...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (memberdata.phone.length === 0) {
      toast.error("Please Enter Phone Number...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (memberdata.dob.length === 0) {
      toast.error("Please Enter Date Of Birth...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      memberdata["id"] =
        localStorage.getItem("memberid") || sessionStorage.getItem("memberid");
      setclicked(true);
      var updatdata = await updateMember(memberdata);
      if (updatdata === "Updated Successfully") {
        toast.success("Profile Updated Successfully...", {
          autoClose: 5000,
          transition: Slide,
        });
        var nofidata = {
          content: `${memberdata.firstname} ${memberdata.lastname} updated his profile details.`,
          status: "true",
          validto: moment().add(7, "days").format("YYYY-MM-DD"),
        };
        createNofification(nofidata);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setclicked(false);
      }
    }
  };
  const editbtn = async () => {
    setmodalIsOpennew(true);
  };
  const download = (data) => {
    window.open(data);
  };
  const savecompany = async () => {
    if (companydata.name.length === 0) {
      toast.error("Please Enter Name...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (companydata.email.length === 0) {
      toast.error("Please Enter Email...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (companydata.phone.length === 0) {
      toast.error("Please Enter Phone Number...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (companydata.state.length === 0) {
      toast.error("Please Enter State...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (companydata.city.length === 0) {
      toast.error("Please Enter City...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (companydata.address.length === 0) {
      toast.error("Please Enter Address...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (companydata.pincode.length === 0) {
      toast.error("Please Enter Pincode...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (companydata.cover.length === 0) {
      toast.error("Please Upload Cover Image...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      companydata["member"] = memberid;
      var createdata = await createcompany(companydata);
      if (createdata.message === "SUCCESS") {
        toast.success("Company Information added Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        var nofidata = {
          content: `${memberdata.firstname} ${memberdata.lastname} updated his Company details.`,
          status: "true",
          validto: moment().add(7, "days").format("YYYY-MM-DD"),
        };
        createNofification(nofidata);
        setclicked(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setclicked(false);
      }
    }
  };
  const updatecompanybtn = async () => {
    if (companydata.name.length === 0) {
      toast.error("Please Enter Name...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (companydata.email.length === 0) {
      toast.error("Please Enter Email...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (companydata.phone.length === 0) {
      toast.error("Please Enter Phone Number...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (companydata.state.length === 0) {
      toast.error("Please Enter State...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (companydata.city.length === 0) {
      toast.error("Please Enter City...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (companydata.address.length === 0) {
      toast.error("Please Enter Address...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (companydata.pincode.length === 0) {
      toast.error("Please Enter Pincode...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (companydata.cover.length === 0) {
      toast.error("Please Upload Cover Image...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      companydata["id"] = updatecompany;
      var update_company = await updateCompany(companydata);
      if (update_company === "Updated Successfully") {
        toast.success("Company Information Updated Successfully...", {
          autoClose: 5000,
          transition: Slide,
        });
        var nofidata = {
          content: `${memberdata.firstname} ${memberdata.lastname} updated his profile details.`,
          status: "true",
          validto: moment().add(7, "days").format("YYYY-MM-DD"),
        };
        createNofification(nofidata);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setclicked(false);
      }
    }
  };
  const familyedit = async (data) => {
    setformdatadata({
      name: data.name,
      email: data.email,
      password: data.password,
      phone: data.phone,
      role: data.role,
      profile: data.profile,
      description: data.description,
      member: data.member,
      dob: data.dob,
      occupation: data.occupation,
      state: data.state,
      city: data.city,
      address: data.address,
      cast: data.cast,
      kolom: data.kolom,
      jathagam: data.jathagam,
      gender: data.gender,
    });
    setIsOpen(true);
    setupdatedid(data.id);
  };
  const deletebtn = async (id) => {
    var deletebtn = await destroyFamily({ id: id });
    if (deletebtn === "Deleted Successfully") {
      toast.success("Family Members Deleted Successfully...", {
        autoClose: 5000,
        transition: Slide,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };
  return (
    <>
      <div className="member-head">
        <div className="member-head1profile">
          <h1 className="profilehead1">My Profile</h1>
        </div>
      </div>
      <div className="profile_div">
        {profiledata.length !== 0 ? (
          <>
            <div className="profile_div1">
              <div className="member-box1">
                <img
                  src={profiledata[0].profile}
                  alt=""
                  className="profileimg"
                />
                <div className="member-img-con">
                  <h2>
                    {profiledata[0].firstname} {profiledata[0].lastname}
                  </h2>
                  <p>{profiledata[0].occupation}</p>
                </div>
              </div>
              <div className="profilehead">
                <h3>Profile Information</h3>
                <BsFillPencilFill className="editicon" onClick={editbtn} />
              </div>
              <div className="profiledata">
                <h4>Name : </h4>
                <h4 className="profilevalue">
                  {profiledata[0].firstname} {profiledata[0].lastname}
                </h4>
              </div>
              <div className="profiledata">
                <h4>Email : </h4>
                <h4 className="profilevalue">{profiledata[0].email}</h4>
              </div>
              <div className="profiledata">
                <h4>Phone : </h4>
                <h4 className="profilevalue">{profiledata[0].phone}</h4>
              </div>
              <div className="profiledata">
                <h4>DOB : </h4>
                <h4 className="profilevalue">{profiledata[0].dob}</h4>
              </div>
            </div>
            <div className="mem-table newprofiletable">
              <p className="viewprofilenew" onClick={editbtn}>
                Edit Personal Information
              </p>
              <table>
                <thead>
                  <tr>
                    <th>Role</th>
                    <td>{profiledata[0].role}</td>
                  </tr>
                  <tr>
                    <th>D.O.B</th>
                    <td>{profiledata[0].dob}</td>
                  </tr>
                  <tr>
                    <th>Phone</th>
                    <td>+91 {profiledata[0].phone}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{profiledata[0].email}</td>
                  </tr>
                  <tr>
                    <th>Occupation</th>
                    <td>{profiledata[0].occupation}</td>
                  </tr>
                  <tr>
                    <th>State</th>
                    <td>
                      {" "}
                      {profiledata[0].state === null
                        ? "-"
                        : profiledata[0].state}
                    </td>
                  </tr>
                  <tr>
                    <th>City</th>
                    <td>
                      {" "}
                      {profiledata[0].city === null ? "-" : profiledata[0].city}
                    </td>
                  </tr>
                  {/* <tr>
                    <th>Caste</th>
                    <td>
                      {" "}
                      {profiledata[0].cast === null ? "-" : profiledata[0].cast}
                    </td>
                  </tr> */}
                  <tr>
                    <th>Kulam</th>
                    <td>
                      {" "}
                      {profiledata[0].kolom === null
                        ? "-"
                        : profiledata[0].kolom}
                    </td>
                  </tr>
                  {/* <tr>
                    <th>Kothiram</th>
                    <td>
                      {profiledata[0].kothiram === null
                        ? "-"
                        : profiledata[0].kothiram}
                    </td>
                  </tr> */}
                  <tr>
                    <th>Jathagam</th>
                    <td>
                      {profiledata[0].jathagam !== null ? (
                        <button
                          onClick={() => {
                            download(profiledata[0].jathagam);
                          }}
                        >
                          Download
                        </button>
                      ) : (
                        "Not Available"
                      )}
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          </>
        ) : null}
      </div>
      <div className="profile_div">
        <div className="profilehead">
          <h2 className="family_name_head">Family Members</h2>
          <button className="addfamilybtn" onClick={openModal}>
            Add New Member
          </button>
        </div>
      </div>
      <div className="home-scrollofficenew">
        <div className="home-scroll1">
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            loop={true}
            autoplay={true}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {allfamily.length !== 0 ? (
              allfamily.map((data, index) => (
                <SwiperSlide className="profil_familydiv" key={index}>
                  <div className="member-box1 familybox">
                    <img
                      src={data.profile}
                      alt=""
                      className="familyprofileimg"
                    />
                    <div className="member-img-con">
                      <h2 className="familyname">{data.name}</h2>
                      <p>{data.role}</p>
                    </div>
                    <div className="btnlist">
                      <BsFillPencilFill
                        className="editicon"
                        onClick={() => {
                          familyedit(data);
                        }}
                      />
                      <FaTrash
                        className="editicondelete"
                        onClick={() => {
                          deletebtn(data.id);
                        }}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <h2>No Family Members Available</h2>
            )}
          </Swiper>
        </div>
      </div>
      <div className="profile_div">
        <div className="profilehead">
          <h2 className="family_name_head">Company Profile</h2>
          {profiledata.length !== 0 ? (
            profiledata[0].Companies.length !== 0 ? (
              <p
                className="viewprofilenew"
                onClick={async () => {
                  setmodalIsOpennewcompany(true);
                  setcompanydata({
                    name: profiledata[0].Companies[0].name,
                    email: profiledata[0].Companies[0].email,
                    phone: profiledata[0].Companies[0].phone,
                    address: profiledata[0].Companies[0].address,
                    state: profiledata[0].Companies[0].state,
                    city: profiledata[0].Companies[0].city,
                    pincode: profiledata[0].Companies[0].pincode,
                    description: profiledata[0].Companies[0].description,
                    cover: profiledata[0].Companies[0].cover,
                  });
                  var checkdata = await Citylist.states.filter((data) => {
                    return data.state == profiledata[0].Companies[0].state;
                  });
                  setcitylist(checkdata[0].districts);
                  setupdatecompany(profiledata[0].Companies[0].id);
                }}
              >
                Edit Company Information
              </p>
            ) : (
              <p
                className="viewprofilenew"
                onClick={() => {
                  setmodalIsOpennewcompany(true);
                }}
              >
                Add Company Information
              </p>
            )
          ) : null}
        </div>
      </div>
      <div>
        {profiledata.length !== 0 ? (
          profiledata[0].Companies.length !== 0 ? (
            <>
              <div className="companydiv">
                <img
                  src={profiledata[0].Companies[0].cover}
                  alt=""
                  className="coverimg"
                />
                <div className="companynewdiv">
                  <div className="companydes">
                    <h1 className="title">
                      {profiledata[0].Companies[0].name}
                    </h1>
                    <h6 className="location">
                      {profiledata[0].Companies[0].city}
                    </h6>
                    <p className="description">
                      {HTMLReactParser(profiledata[0].Companies[0].description)}
                    </p>
                  </div>
                  <div className="contatable">
                    <h5 className="contacttag">Contact Details:</h5>
                    <div className="mem-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Phone</th>
                            <td>{profiledata[0].Companies[0].phone}</td>
                          </tr>
                          <tr>
                            <th>Email</th>
                            <td>{profiledata[0].Companies[0].email}</td>
                          </tr>
                          <tr>
                            <th>Address</th>
                            <td>
                              {profiledata[0].Companies[0].address},{" "}
                              {profiledata[0].Companies[0].city},{" "}
                              {profiledata[0].Companies[0].state},{" "}
                              {profiledata[0].Companies[0].pincode}
                            </td>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <h2>No Company Information Available</h2>
          )
        ) : null}
      </div>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        className="newsmodel"
      >
        <div>
          <div>
            <button className="closebtn" onClick={closeModal}>
              ✕
            </button>
            {updatedid === null ? (
              <h3 className="modelhead">Add New Family</h3>
            ) : (
              <h3 className="modelhead">Update Family</h3>
            )}
          </div>
          <div className="divider mt-2"></div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Name"
              defaultValue={formdatadata.name}
              name="name"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="date"
              placeholder="Date Of Birth"
              defaultValue={formdatadata.dob}
              name="dob"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Email"
              defaultValue={formdatadata.email}
              name="email"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Phone"
              defaultValue={formdatadata.phone}
              name="phone"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <select
              defaultValue={formdatadata.gender}
              name="gender"
              onChange={changeformdta}
              className="input inputfamily w-full"
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>

          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Occupation"
              defaultValue={formdatadata.occupation}
              name="occupation"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          {/* <div className="form-control w-full mt-4 mb-2">
            <select
              defaultValue={formdatadata.occupation}
              name="occupation"
              onChange={changeformdta}
              className="input inputfamily w-full"
            >
              <option value="">Select Occupation</option>
              <option value="Manufacturers & Merchandise traders">
                Manufacturers & Merchandise traders
              </option>
              <option value="Agricultural">Agricultural</option>
              <option value="Alcohol/Beverages">Alcohol/Beverages</option>
              <option value="Animal Feed & Poultry">
                Animal Feed & Poultry
              </option>
              <option value="Automobiles">Automobiles</option>
              <option value="Builders">Builders</option>
              <option value="Hardware">Hardware</option>
              <option value="Hospital">Hospital</option>
              <option value="Clinic/Lab">Clinic/Lab</option>
              <option value="Chemicals">Chemicals</option>
              <option value="Computer Peripherals">Computer Peripherals</option>
              <option value="Consumer Electronics">Consumer Electronics</option>
              <option value="Cosmetics">Cosmetics</option>
              <option value="Electrical Equipment">Electrical Equipment</option>
              <option value="Food Products & Bakery">
                {" "}
                Food Products & Bakery
              </option>
              <option value="Furniture">Furniture</option>
              <option value="Gifts & Novelty Items">
                Gifts & Novelty Items
              </option>
              <option value="Handicrafts">Handicrafts</option>
              <option value="Industrial Electricals">
                Industrial Electricals
              </option>
              <option value="Music Systems">Music Systems</option>
              <option value="Pharmaceuticals">Pharmaceuticals</option>
              <option value="Printing">Printing</option>
              <option value="PVC&Rubber products">PVC&Rubber products</option>
              <option value="Stationaries">Stationaries</option>
              <option value="Retail Shops">Retail Shops</option>
              <option value="Textiles">Textiles</option>
              <option value="Workshop">Workshop</option>
              <option value="Advertising Agencies">
                {" "}
                Advertising Agencies
              </option>
              <option value="Ayurvedic">Ayurvedic</option>
              <option value="Banks">Banks</option>
              <option value="Building Contractors">Building Contractors</option>
              <option value="Business Consultants">Business Consultants</option>
              <option value="Computer Maintenance">Computer Maintenance</option>
              <option value="Education Consultant">Education Consultant</option>
              <option value="Courier Services">Courier Services</option>
              <option value="Drilling Rig Contractors">
                Drilling Rig Contractors
              </option>
              <option value="Electrical Contractors">
                Electrical Contractors
              </option>{" "}
              <option value="Event Organisors">Event Organisors</option>
              <option value="Finance">Finance</option>
              <option value="Hotels/Holiday Resorts">
                Hotels/Holiday Resorts
              </option>
              <option value="Manpower Recruitment">Manpower Recruitment</option>
              <option value="NGO's">NGO's</option>
              <option value="Real Estate">Real Estate</option>
              <option value="Software/Web Developers">
                Software/Web Developers
              </option>
              <option value="Tansporters/C&F Agents">
                Tansporters/C&F Agents
              </option>
              <option value="Training Services">Training Services</option>
              <option value="Travel Agency">Travel Agency</option>
            </select>
          </div> */}
          <div className="form-control w-full mt-4 mb-2">
            <textarea
              type="text"
              placeholder="Description"
              defaultValue={formdatadata.description}
              name="description"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <select
              defaultValue={formdatadata.state}
              name="state"
              className="input inputfamily w-full"
              onChange={changeformdta}
            >
              <option value="">State</option>
              {statelist.length !== 0
                ? statelist.map((data, index) => (
                    <option value={data.state} key={index}>
                      {data.state}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <select
              defaultValue={formdatadata.city}
              name="city"
              className="input inputfamily w-full"
              onChange={changeformdta}
            >
              <option value="">City</option>
              {citylist.length !== 0
                ? citylist.map((data, index) => (
                    <option value={data} key={index}>
                      {data}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <textarea
              type="text"
              placeholder="Address"
              defaultValue={formdatadata.address}
              name="address"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Relation"
              defaultValue={formdatadata.role}
              name="role"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          {/* <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Caste"
              defaultValue={formdatadata.cast}
              name="cast"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div> */}
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Kulam"
              defaultValue={formdatadata.kolom}
              name="kolom"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          {/* <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Kothiram"
              defaultValue={formdatadata.kothiram}
              name="kothiram"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div> */}
          <div className="form-control w-full mt-4 mb-2">
            <h5 className="newlabel">Profile</h5>
            <input
              type="file"
              placeholder="Kothiram"
              name="jathagam"
              className="input inputfamily w-full"
              onChange={getprofile}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <h5 className="newlabel">Jathagam</h5>
            <input
              type="file"
              placeholder="Kothiram"
              name="jathagam"
              className="input inputfamily w-full"
              onChange={geturl}
            />
          </div>

          <div className="divider mt-2"></div>
          <div className="modal-action">
            {clicked === true ? (
              <button className="savebtn">Please Wait...</button>
            ) : updatedid === null ? (
              <button className="savebtn" onClick={save_btn}>
                Save
              </button>
            ) : (
              <button className="savebtn" onClick={updatebtn}>
                Update
              </button>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpennew}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModalnew}
        style={customStyles}
        className="newsmodel"
      >
        <div>
          <div>
            <button className="closebtn" onClick={closeModalnew}>
              ✕
            </button>
            <h3 className="modelhead">Edit Profile</h3>
          </div>
          <div className="divider mt-2"></div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="First name"
              defaultValue={memberdata.firstname}
              name="firstname"
              className="input inputfamily w-full"
              onChange={changeformdata}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Last name"
              defaultValue={memberdata.lastname}
              name="lastname"
              className="input inputfamily w-full"
              onChange={changeformdata}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="date"
              placeholder="Date Of Birth"
              defaultValue={memberdata.dob}
              name="dob"
              className="input inputfamily w-full"
              onChange={changeformdata}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Email"
              defaultValue={memberdata.email}
              name="email"
              className="input inputfamily w-full"
              onChange={changeformdata}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Phone"
              defaultValue={memberdata.phone}
              name="phone"
              className="input inputfamily w-full"
              onChange={changeformdata}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <select
              defaultValue={memberdata.gender}
              name="gender"
              onChange={changeformdata}
              className="input inputfamily w-full"
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Occupation"
              defaultValue={memberdata.occupation}
              name="occupation"
              className="input inputfamily w-full"
              onChange={changeformdata}
            />
          </div>
          {/* <div className="form-control w-full mt-4 mb-2">
            <select
              defaultValue={memberdata.occupation}
              name="occupation"
              onChange={changeformdata}
              className="input inputfamily w-full"
            >
              <option value="">Select Occupation</option>
              <option value="Manufacturers & Merchandise traders">
                Manufacturers & Merchandise traders
              </option>
              <option value="Agricultural">Agricultural</option>
              <option value="Alcohol/Beverages">Alcohol/Beverages</option>
              <option value="Animal Feed & Poultry">
                Animal Feed & Poultry
              </option>
              <option value="Automobiles">Automobiles</option>
              <option value="Builders">Builders</option>
              <option value="Hardware">Hardware</option>
              <option value="Hospital">Hospital</option>
              <option value="Clinic/Lab">Clinic/Lab</option>
              <option value="Chemicals">Chemicals</option>
              <option value="Computer Peripherals">Computer Peripherals</option>
              <option value="Consumer Electronics">Consumer Electronics</option>
              <option value="Cosmetics">Cosmetics</option>
              <option value="Electrical Equipment">Electrical Equipment</option>
              <option value="Food Products & Bakery">
                {" "}
                Food Products & Bakery
              </option>
              <option value="Furniture">Furniture</option>
              <option value="Gifts & Novelty Items">
                Gifts & Novelty Items
              </option>
              <option value="Handicrafts">Handicrafts</option>
              <option value="Industrial Electricals">
                Industrial Electricals
              </option>
              <option value="Music Systems">Music Systems</option>
              <option value="Pharmaceuticals">Pharmaceuticals</option>
              <option value="Printing">Printing</option>
              <option value="PVC&Rubber products">PVC&Rubber products</option>
              <option value="Stationaries">Stationaries</option>
              <option value="Retail Shops">Retail Shops</option>
              <option value="Textiles">Textiles</option>
              <option value="Workshop">Workshop</option>
              <option value="Advertising Agencies">
                {" "}
                Advertising Agencies
              </option>
              <option value="Ayurvedic">Ayurvedic</option>
              <option value="Banks">Banks</option>
              <option value="Building Contractors">Building Contractors</option>
              <option value="Business Consultants">Business Consultants</option>
              <option value="Computer Maintenance">Computer Maintenance</option>
              <option value="Education Consultant">Education Consultant</option>
              <option value="Courier Services">Courier Services</option>
              <option value="Drilling Rig Contractors">
                Drilling Rig Contractors
              </option>
              <option value="Electrical Contractors">
                Electrical Contractors
              </option>{" "}
              <option value="Event Organisors">Event Organisors</option>
              <option value="Finance">Finance</option>
              <option value="Hotels/Holiday Resorts">
                Hotels/Holiday Resorts
              </option>
              <option value="Manpower Recruitment">Manpower Recruitment</option>
              <option value="NGO's">NGO's</option>
              <option value="Real Estate">Real Estate</option>
              <option value="Software/Web Developers">
                Software/Web Developers
              </option>
              <option value="Tansporters/C&F Agents">
                Tansporters/C&F Agents
              </option>
              <option value="Training Services">Training Services</option>
              <option value="Travel Agency">Travel Agency</option>
            </select>
          </div> */}
          <div className="form-control w-full mt-4 mb-2">
            <select
              defaultValue={memberdata.state}
              name="state"
              className="input inputfamily w-full"
              onChange={changeformdata}
            >
              <option value="">State</option>
              {statelist.length !== 0
                ? statelist.map((data, index) => (
                    <option value={data.state} key={index}>
                      {data.state}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <select
              defaultValue={memberdata.city}
              name="city"
              className="input inputfamily w-full"
              onChange={changeformdata}
            >
              <option value="">City</option>
              {citylist.length !== 0
                ? citylist.map((data, index) => (
                    <option value={data} key={index}>
                      {data}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <textarea
              type="text"
              placeholder="Address"
              defaultValue={memberdata.address}
              name="address"
              className="input inputfamily w-full"
              onChange={changeformdata}
            />
          </div>
          {/* <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Role"
              defaultValue={memberdata.role}
              name="role"
              className="input inputfamily w-full"
              onChange={changeformdata}
            />
          </div> */}
          {/* <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Caste"
              defaultValue={memberdata.cast}
              name="cast"
              className="input inputfamily w-full"
              onChange={changeformdata}
            />
          </div> */}
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Kulam"
              defaultValue={memberdata.kolom}
              name="kolom"
              className="input inputfamily w-full"
              onChange={changeformdata}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Kothiram"
              defaultValue={memberdata.kothiram}
              name="kothiram"
              className="input inputfamily w-full"
              onChange={changeformdata}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="file"
              placeholder="Kothiram"
              name="jathagam"
              className="input inputfamily w-full"
              onChange={geturlnew}
            />
          </div>

          <div className="divider mt-2"></div>
          <div className="modal-action">
            {clicked === true ? (
              <button className="savebtn">Please Wait...</button>
            ) : clicked === true ? (
              <button className="savebtn">Please Wait...</button>
            ) : (
              <button className="savebtn" onClick={savemember}>
                Update
              </button>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpennewcompany}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closecompanymodel}
        style={customStyles}
        className="newsmodel"
      >
        <div>
          <div>
            <button className="closebtn" onClick={closecompanymodel}>
              ✕
            </button>
            <h3 className="modelhead">Company Information</h3>
          </div>
          <div className="divider mt-2"></div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Name"
              defaultValue={companydata.name}
              name="name"
              className="input inputfamily w-full"
              onChange={changecompanydata}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Email"
              defaultValue={companydata.email}
              name="email"
              className="input inputfamily w-full"
              onChange={changecompanydata}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="number"
              placeholder="Phone"
              defaultValue={companydata.phone}
              name="phone"
              className="input inputfamily w-full"
              onChange={changecompanydata}
            />
          </div>

          <div className="form-control w-full mt-4 mb-2">
            <select
              defaultValue={companydata.state}
              name="state"
              className="input inputfamily w-full"
              onChange={changecompanydata}
            >
              <option value="">State</option>
              {statelist.length !== 0
                ? statelist.map((data, index) => (
                    <option value={data.state} key={index}>
                      {data.state}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <select
              defaultValue={companydata.city}
              name="city"
              className="input inputfamily w-full"
              onChange={changecompanydata}
            >
              <option value="">City</option>
              {citylist.length !== 0
                ? citylist.map((data, index) => (
                    <option value={data} key={index}>
                      {data}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <textarea
              type="text"
              placeholder="Address"
              defaultValue={companydata.address}
              name="address"
              className="input inputfamily w-full"
              onChange={changecompanydata}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Pincode"
              defaultValue={companydata.pincode}
              name="pincode"
              className="input inputfamily w-full"
              onChange={changecompanydata}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <textarea
              type="text"
              placeholder="Description"
              defaultValue={companydata.description}
              name="description"
              className="input inputfamily w-full"
              onChange={changecompanydata}
            />
          </div>

          <div className="form-control w-full mt-4 mb-2">
            <h4 className="labeltag">Cover Image</h4>
            <input
              type="file"
              placeholder="Kothiram"
              name="jathagam"
              className="input inputfamily w-full"
              onChange={geturlcover}
            />
          </div>

          <div className="divider mt-2"></div>
          <div className="modal-action">
            {clicked === true ? (
              <button className="savebtn">Please Wait...</button>
            ) : clicked === true ? (
              <button className="savebtn">Please Wait...</button>
            ) : updatecompany === null ? (
              <button className="savebtn" onClick={savecompany}>
                Save
              </button>
            ) : (
              <button className="savebtn" onClick={updatecompanybtn}>
                Update
              </button>
            )}
          </div>
        </div>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default Profile;
