import { Educationfunddonor } from "../axios";

export const viewallEducationfunddonor = async (data) => {
  var viewallEducationfunddonor = await Educationfunddonor.post(
    `/viewalleducationfunddonor`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallEducationfunddonor;
};

export const createEducationfunddonor = async (data) => {
  var createEducationfunddonor = await Educationfunddonor.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createEducationfunddonor;
};

export const updateEducationfunddonor = async (data) => {
  var updateEducationfunddonor = await Educationfunddonor.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateEducationfunddonor;
};

export const destroyEducationfunddonor = async (data) => {
  var destroyEducationfunddonor = await Educationfunddonor.post(
    `/destroy`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyEducationfunddonor;
};

export const viewbyEducationfunddonor = async (data) => {
  var viewbyEducationfunddonor = await Educationfunddonor.post(
    `/viewbyfund`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewbyEducationfunddonor;
};
