/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import "../../Assets/Css/Members.css";
import { BsTelephoneFill } from "react-icons/bs";
import { AiTwotoneMail } from "react-icons/ai";
import { viewallMember } from "../../Api/member";
import { viewallFamily } from "../../Api/family";

const Members = () => {
  const [alldata, setalldata] = useState([]);
  const [filterdata, setfilterdata] = useState([]);
  const [alldatafamily, setalldatafamily] = useState([]);
  const [filterdatafamily, setfilterdatafamily] = useState([]);
  const [kolomdata, setkolomdata] = useState([]);
  // const [kothiramdata, setkothiramdata] = useState([]);
  const [citydata, setcitydata] = useState([]);
  const [statedata, setstatedata] = useState([]);

  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    var alldata = await viewallMember();
    if (alldata.length !== 0) {
      setalldata(alldata);
      setfilterdata(alldata);
      var kolomdata = [],
        // kothiramdata = [],
        citydata = [],
        statedata = [];
      for (var i = 0; i < alldata.length; i++) {
        if (alldata[i].kolom !== null) {
          kolomdata.push(alldata[i].kolom.toLowerCase());
        }
        // if (alldata[i].kothiram !== null) {
        //   kothiramdata.push(alldata[i].kothiram.toLowerCase());
        // }
        if (alldata[i].city !== null) {
          citydata.push(alldata[i].city.toLowerCase());
        }
        if (alldata[i].state !== null) {
          statedata.push(alldata[i].state.toLowerCase());
        }
      }
      var uniq = [...new Set(kolomdata)];
      // var kothiramdatauniq = [...new Set(kothiramdata)];
      var cityuniq = [...new Set(citydata)];
      var stateuniq = [...new Set(statedata)];
      setkolomdata(uniq);
      // setkothiramdata(kothiramdatauniq);
      setcitydata(cityuniq);
      setstatedata(stateuniq);

      var allfamily = await viewallFamily();
      if (allfamily.length !== 0) {
        setalldatafamily(allfamily);
        setfilterdatafamily(allfamily);
        var kolomdata = [],
          // kothiramdata = [],
          citydata = [],
          statedata = [];
        for (var i = 0; i < allfamily.length; i++) {
          if (allfamily[i].kolom !== null) {
            kolomdata.push(allfamily[i].kolom.toLowerCase());
          }
          // if (allfamily[i].kothiram !== null) {
          //   kothiramdata.push(allfamily[i].kothiram.toLowerCase());
          // }
          if (allfamily[i].city !== null) {
            citydata.push(allfamily[i].city.toLowerCase());
          }
          if (allfamily[i].state !== null) {
            statedata.push(allfamily[i].state.toLowerCase());
          }
        }
        var uniqfamily = [...new Set(kolomdata)];
        // var kothiramdatauniqfamily = [...new Set(kothiramdata)];
        var cityuniqfamily = [...new Set(citydata)];
        var stateuniqfamily = [...new Set(statedata)];

        const mergedkolom = uniq.concat(
          uniqfamily.filter((item) => !uniq.includes(item))
        );
        // const mergedkothiram = kothiramdatauniq.concat(
        //   kothiramdatauniqfamily.filter(
        //     (item) => !kothiramdatauniq.includes(item)
        //   )
        // );
        const mergedcity = cityuniq.concat(
          cityuniqfamily.filter((item) => !cityuniq.includes(item))
        );
        const mergedstate = stateuniq.concat(
          stateuniqfamily.filter((item) => !stateuniq.includes(item))
        );

        setkolomdata(mergedkolom);
        // setkothiramdata(mergedkothiram);
        setcitydata(mergedcity);
        setstatedata(mergedstate);
      }
    }
  };
  const changetrade = async (e) => {
    if (e.length !== 0) {
      var filterdata = [];
      for (var i = 0; i < alldata.length; i++) {
        if (
          alldata[i].firstname.toLowerCase().match(e.toLowerCase()) ||
          alldata[i].lastname.toLowerCase().match(e.toLowerCase()) ||
          alldata[i].phone.toLowerCase().match(e.toLowerCase())
        ) {
          filterdata.push(alldata[i]);
        }
      }
      var newfilter = [];
      for (var i = 0; i < alldatafamily.length; i++) {
        if (
          alldatafamily[i].name.toLowerCase().match(e.toLowerCase()) ||
          alldatafamily[i].phone.toLowerCase().match(e.toLowerCase())
        ) {
          newfilter.push(alldatafamily[i]);
        }
      }
      setfilterdata(filterdata);
      setfilterdatafamily(newfilter);
    } else {
      setfilterdata(alldata);
      setfilterdatafamily(alldatafamily);
    }
  };
  const viewmember = async (id) => {
    window.location.replace(`/memberdetails`);
    sessionStorage.setItem("memberdetailsid", id);
  };
  const viewfamily = async (id) => {
    window.location.replace(`/familydetails`);
    sessionStorage.setItem("familydetailsid", id);
  };
  const changekulam = async (e) => {
    var alldatanew = [];
    for (var i = 0; i < alldata.length; i++) {
      if (alldata[i].kolom !== null) {
        if (alldata[i].kolom.toLowerCase() == e) {
          alldatanew.push(alldata[i]);
        }
      }
    }
    setfilterdata(alldatanew);
    if (alldatafamily.length !== 0) {
      var newfilter = [];
      for (var i = 0; i < alldatafamily.length; i++) {
        if (alldatafamily[i].kolom !== null) {
          if (alldatafamily[i].kolom.toLowerCase() == e) {
            newfilter.push(alldatafamily[i]);
          }
        }
      }
      setfilterdatafamily(newfilter);
    }
  };

  const changestate = async (e) => {
    var alldatanew = [];
    for (var i = 0; i < alldata.length; i++) {
      if (alldata[i].state !== null) {
        if (alldata[i].state.toLowerCase() == e) {
          alldatanew.push(alldata[i]);
        }
      }
    }
    setfilterdata(alldatanew);
    if (alldatafamily.length !== 0) {
      var newfilter = [];
      for (var i = 0; i < alldatafamily.length; i++) {
        if (alldatafamily[i].state !== null) {
          if (alldatafamily[i].state.toLowerCase() == e) {
            newfilter.push(alldatafamily[i]);
          }
        }
      }
      setfilterdatafamily(newfilter);
    }
  };
  const changecity = async (e) => {
    var alldatanew = [];
    for (var i = 0; i < alldata.length; i++) {
      if (alldata[i].city !== null) {
        if (alldata[i].city.toLowerCase() == e) {
          alldatanew.push(alldata[i]);
        }
      }
    }
    setfilterdata(alldatanew);
    if (alldatafamily.length !== 0) {
      var newfilter = [];
      for (var i = 0; i < alldatafamily.length; i++) {
        if (alldatafamily[i].city !== null) {
          if (alldatafamily[i].city.toLowerCase() == e) {
            newfilter.push(alldatafamily[i]);
          }
        }
      }
      setfilterdatafamily(newfilter);
    }
  };
  const changearea = async (e) => {
    var alldatanew = [];
    for (var i = 0; i < alldata.length; i++) {
      if (alldata[i].area !== null) {
        if (alldata[i].area.toLowerCase() == e) {
          alldatanew.push(alldata[i]);
        }
      }
    }

    setfilterdata(alldatanew);
    if (alldatafamily.length !== 0) {
      var newfilter = [];
      for (var i = 0; i < alldatafamily.length; i++) {
        if (alldatafamily[i].Member.area !== null) {
          if (alldatafamily[i].Member.area.toLowerCase() == e) {
            newfilter.push(alldatafamily[i]);
          }
        }
      }
      setfilterdatafamily(newfilter);
    }
  };
  const changegender = async (e) => {
    var alldatanew = [];
    for (var i = 0; i < alldata.length; i++) {
      if (alldata[i].gender !== null) {
        if (alldata[i].gender.toLowerCase() == e) {
          alldatanew.push(alldata[i]);
        }
      }
    }
    setfilterdata(alldatanew);
    if (alldatafamily.length !== 0) {
      var newfilter = [];
      for (var i = 0; i < alldatafamily.length; i++) {
        if (alldatafamily[i].gender !== null) {
          if (alldatafamily[i].gender.toLowerCase() == e) {
            newfilter.push(alldatafamily[i]);
          }
        }
      }
      setfilterdatafamily(newfilter);
    }
  };

  const resetbtn = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="member-head">
        <div className="member-head1">
          <h1>Members</h1>
          <p>
            All staff at community club are volunteers, we appreciate their
            dedication to the program keeping us operating.
          </p>
        </div>
      </div>
      <div className="member-search">
        <div className="member-search1">
          <p>
            <input
              type="text"
              placeholder="Search for a member name or phone number here!"
              onChange={(e) => {
                changetrade(e.target.value);
              }}
            />
            <BiSearch className="members-icon" />
          </p>
        </div>
      </div>
      <div className="filterdiv">
        <div>
          <select
            className="selectdivfilter"
            onChange={(e) => {
              changegender(e.target.value);
            }}
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div>
          <select
            className="selectdivfilter"
            onChange={(e) => {
              changekulam(e.target.value);
            }}
          >
            <option value="">Select Kulam</option>
            {kolomdata.length !== 0
              ? kolomdata.map((data, index) => (
                  <option value={data} key={index}>
                    {data}
                  </option>
                ))
              : null}
          </select>
        </div>
        {/* <div>
          <select
            className="selectdivfilter"
            onChange={(e) => {
              changekothiram(e.target.value);
            }}
          >
            <option value="">Select Kothiram</option>
            {kothiramdata.length !== 0
              ? kothiramdata.map((data, index) => (
                  <option value={data} key={index}>
                    {data}
                  </option>
                ))
              : null}
          </select>
        </div> */}

        <div>
          <select
            className="selectdivfilter"
            onChange={(e) => {
              changestate(e.target.value);
            }}
          >
            <option value="">Select State</option>
            {statedata.length !== 0
              ? statedata.map((data, index) => (
                  <option value={data} key={index}>
                    {data}
                  </option>
                ))
              : null}
          </select>
        </div>
        <div>
          <select
            className="selectdivfilter"
            onChange={(e) => {
              changecity(e.target.value);
            }}
          >
            <option value="">Select City</option>
            {citydata.length !== 0
              ? citydata.map((data, index) => (
                  <option value={data} key={index}>
                    {data}
                  </option>
                ))
              : null}
          </select>
        </div>
        <div>
          <select
            className="selectdivfilter"
            onChange={(e) => {
              changearea(e.target.value);
            }}
          >
            <option value="">Select Area</option>
            <option value="தடாகம்ரோடு">தடாகம்ரோடு</option>
            <option value="மருதமலைரோடு">மருதமலைரோடு</option>
            <option value="மேட்டுப்பாளையம்ரோடு">மேட்டுப்பாளையம்ரோடு</option>
            <option value="சத்திரோடு">சத்திரோடு</option>
            <option value="அவினாசிரோடு">அவினாசிரோடு</option>
            <option value="திருச்சிரோடு">திருச்சிரோடு</option>
            <option value="பொள்ளாச்சிரோடு">பொள்ளாச்சிரோடு</option>
            <option value="Others">Others</option>
          </select>
        </div>
        <div>
          <button className="resetbtn" onClick={resetbtn}>
            Reset
          </button>
        </div>
      </div>

      <div className="member-content">
        <div className="member-contents1">
          {/* <h1>Listed {filterdata.length} Members on the selected category</h1> */}
          <div className="member-cards">
            {filterdata.length !== 0
              ? filterdata.map((data, index) => (
                  <div
                    className="member-card1"
                    key={index}
                    onClick={() => {
                      viewmember(data.id);
                    }}
                  >
                    <div className="member-box1">
                      <div className="member-img">
                        <img src={data.profile} alt="" />
                      </div>
                      <div className="member-img-con">
                        <h2>
                          {data.firstname} {data.lastname}
                        </h2>
                        <p>{data.occupation}</p>
                      </div>
                    </div>
                    <div className="member-contact">
                      <p>
                        <BsTelephoneFill className="member-i" />
                        {data.phone}
                      </p>
                      <p>
                        <AiTwotoneMail className="member-i" />
                        <p>{data.email}</p>
                      </p>
                    </div>
                  </div>
                ))
              : null}
            {/* {filterdatafamily.length !== 0
              ? filterdatafamily.map((data, index) => (
                  <div
                    className="member-card1"
                    key={index}
                    onClick={() => {
                      viewfamily(data.id);
                    }}
                  >
                    <div className="member-box1">
                      <div className="member-img">
                        <img src={data.profile} alt="" />
                      </div>
                      <div className="member-img-con">
                        <h2>{data.name}</h2>
                        <h6 className="memberlabel">
                          Member of{" "}
                          <b>
                            {data.Member.firstname} {data.Member.lastname}
                          </b>
                        </h6>
                        <p>{data.occupation}</p>
                      </div>
                    </div>
                    <div className="member-contact">
                      <p>
                        <BsTelephoneFill className="member-i" />
                        {data.phone}
                      </p>
                      <p>
                        <AiTwotoneMail className="member-i" />
                        <p>{data.email}</p>
                      </p>
                    </div>
                  </div>
                ))
              : null} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Members;
