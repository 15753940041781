import React from "react";
import Navbar from "../Component/Navbar";
import Footer from "../Component/Footer";
import Company from "../Component/Members/Companydetails";

const Companydetails = () => {
  return (
    <>
      <Navbar />
      <Company />
      <Footer />
    </>
  );
};

export default Companydetails;
