/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from "react";
const Test = () => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: true,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  return (
    <>
      <div id="google_translate_element"></div>

      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
      />
      <div class="floating_btn">
        <a
          target="_blank"
          href="https://api.whatsapp.com/send?phone=919790456785&text=Hello+I+would+like+more+information+&type=phone_number&app_absent=0"
        >
          <div class="contact_icon">
            <i class="fa fa-whatsapp my-float"></i>
          </div>
        </a>
        <p class="text_icon">Talk to us?</p>
      </div>
    </>
  );
};

export default Test;
