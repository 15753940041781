import { Company } from "../axios";

export const viewByidcompany = async (data) => {
  var viewByidcompany = await Company.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidcompany;
};

export const createcompany = async (data) => {
  var createcompany = await Company.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createcompany;
};

export const updateCompany = async (data) => {
  var updatecompany = await Company.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updatecompany;
};
