/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../Assets/Css/Register.css";
import registerimg from "../Assets/images/login.png";
import registerimg1 from "../Assets/images/2 135.png";
import { BiLogoFacebook } from "react-icons/bi";
import { AiOutlineGoogle } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginMember } from "../Api/member";
import { useNavigate } from "react-router-dom";
import { firebase, auth } from "../database/firebase";
import { ViewAllAdvertisement } from "../Api/advertisement";

const Login = () => {
  const navigate = useNavigate();
  const [clicked, setclicked] = useState("false");
  const [show, setshow] = useState(false);
  const [phone, setphone] = useState("");
  const [final, setfinal] = useState(null);
  const [otp, setotp] = useState("");

  const loginbtn = async () => {
    if (phone === "" || phone.length < 10) return;
    // setclicked(true);
    var checkuser = await loginMember({ phone: phone });
    if (checkuser.message === "SUCCESS") {
      let verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");
      auth
        .signInWithPhoneNumber(`+91${phone}`, verify)
        .then((result) => {
          setfinal(result);
          toast.success("Otp Send your mobile number successfully...", {
            autoClose: 2000,
            transition: Slide,
          });
          setshow(true);
          setclicked(false);
        })
        .catch((err) => {
          setclicked(false);
          window.location.reload();
        });
    } else {
      toast.error(checkuser.message, {
        autoClose: 2000,
        transition: Slide,
      });
      setclicked(false);
    }
  };
  var memberid =
    localStorage.getItem("memberid") || sessionStorage.getItem("memberid");
  useEffect(() => {
    checkdata();
  }, [memberid]);
  const checkdata = async () => {
    if (memberid !== null) {
      window.location.replace("/");
    }
  };

  const ValidateOtp = async () => {
    if (otp === null || final === null) return;
    setclicked(true);
    final
      .confirm(otp)
      .then(async (result) => {
        toast.success("Otp Verified successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        var checkuser = await loginMember({ phone: phone });
        if (checkuser.message === "SUCCESS") {
          toast.success("Welcome Back...", {
            autoClose: 2000,
            transition: Slide,
          });
          sessionStorage.setItem("token", checkuser.data.userdata[0].token);
          sessionStorage.setItem("memberid", checkuser.data.userdata[0].id);
          setclicked(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error(checkuser.message, {
            autoClose: 2000,
            transition: Slide,
          });
          setclicked(false);
        }
      })
      .catch((err) => {
        setclicked(false);
        alert("Wrong code");
      });
  };
  const [addata, setaddata] = useState([]);
  useEffect(() => {
    getmydata();
  }, []);
  const getmydata = async () => {
    var alladvertisement = await ViewAllAdvertisement();
    setaddata(alladvertisement);
  };
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentBannerIndex((prevIndex) =>
        prevIndex === addata.length - 1 ? 0 : prevIndex + 1
      );
    }, 10000); // 10000 milliseconds = 10 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [addata]);
  return (
    <>
      <div className="Register">
        <div className="Register1">
          <img src={registerimg} alt="" />
        </div>
        <div className="Register2">
          <div className="logo">
            <img
              src={registerimg1}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.location.replace("/");
              }}
            />
            <p>Thenmaavatta Okkaligar (Kaappu) </p>
            <p>Mahajana Nala Sangam, Kovai</p>
          </div>
          <div className="register-title">
            <h1>Login to your account</h1>
            <div className="inputss" style={{ display: show && "none" }}>
              <input
                value={phone}
                onChange={(e) => {
                  setphone(e.target.value);
                }}
                placeholder="Phone number"
              />
              <div id="recaptcha-container" className="forget"></div>
              {clicked === true ? (
                <button className="loginbtn">Please wait...</button>
              ) : (
                <>
                  <button className="loginbtn" onClick={loginbtn}>
                    Send OTP
                  </button>
                </>
              )}
            </div>
            <div className="inputss" style={{ display: !show && "none" }}>
              <input
                type="text"
                placeholder={"Enter your OTP"}
                onChange={(e) => {
                  setotp(e.target.value);
                }}
              ></input>
              {clicked === true ? (
                <button className="loginbtn">Please wait...</button>
              ) : (
                <>
                  <button className="loginbtn" onClick={ValidateOtp}>
                    Verify
                  </button>
                  {/* <br />
                  <button className="loginbtn" onClick={loginbtn}>
                    Resend
                  </button> */}
                </>
              )}
            </div>
            {/* <div className="icons">
              <BiLogoFacebook className="icon1" />
              <AiOutlineGoogle className="icon2" />
              <BsTwitter className="icon3" />
              <BsYoutube className="icon4" />
            </div> */}
            <h5
              className="donttag"
              onClick={() => {
                navigate("/register");
              }}
            >
              Don't have an account ? <a>Sign Up Free!</a>
            </h5>
          </div>
          <div className="loginadd">
            {addata.map((image, index) => (
              <div
                key={index}
                style={{
                  display: index === currentBannerIndex ? "block" : "none",
                }}
                className="advertisement-banner"
              >
                <img src={image.image} alt={`Banner ${index + 1}`} className="loginimg"/>
              </div>
            ))}
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default Login;
