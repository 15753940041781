/* eslint-disable no-unused-vars */
import React from "react";
import Historyimg from "../../Assets/images/12 35.jpg";
import Leaders1 from "../../Assets/images/Leaders1.jpg";
import Leaders2 from "../../Assets/images/Leaders2.jpg";
import Leaders3 from "../../Assets/images/Leaders3.jpg";
import Leaders4 from "../../Assets/images/Leaders4.jpg";
import Leaders5 from "../../Assets/images/Leaders5.jpg";
import Leaders6 from "../../Assets/images/Leaders6.jpg";
import "../../Assets/Css/history.css";

const Index = () => {
  return (
    <>
      <div className="member-head">
        <div className="member-head1">
          <h1>OUR PROUD HISTORY</h1>
        </div>
      </div>
      <div className="fillpage">
        <div className="fillpage1">
          <div className="educnt">
            <div className="edu-image">
              <img src={Historyimg} alt="" />
            </div>
          </div>
          <div className="eduinfo">
            <p>
              ஒக்கலிகர் காமுகுல ஒக்கலிக கவுடர் (kamukula okkalika gowda)
              என்பவர்கள் தமிழ்நாட்டில் வாழும் ஒரு சாதியினராவர். இவர்கள்
              தமிழ்நாட்டின் தேனி, திண்டுக்கல், மதுரை, கோயமுத்தூர் ஆகிய
              மாவட்டங்களில் வாழ்கின்றனர்.
            </p>
            <p>
              காமுகுல ஒக்கலிக கவுடர் குறிப்பிடத்தக்க மக்கள்தொகை கொண்ட
              பகுதிகள்-தமிழ் நாடு, கர்நாடகம் மொழி(கள்)-தமிழ், கன்னடம்
              சமயம்-இந்து சமயம் தொடர்புள்ள இனக்குழுக்கள்-வொக்கலிகர் அனைத்துப்
              பிரிவும் இவர்கள் கன்னடமும், தமிழும் கலந்த மொழியைப் பேசுகிறார்கள்.
              சில நூற்றாண்டுகளுக்கு முன்னர் அயலார் படையெடுப்பின்போது
              துங்கபத்திரை ஆற்றின் கரையில் இருந்து தமிழ்நாட்டுக்கு இவர்கள்
              குடிபெயர்ந்தவர்கள் என செவிவழி செய்தி இவர்களிடம் நிலவுகிறது.{" "}
            </p>
            <p>
              இவர்களிடையே, பசிலிவார், எம்மேனுவார், சௌந்தரிவார், ஆவுலார்,
              கரவனார்,ஏந்தார்,தசரிவார்,படவனார்(அரண்மனையார்,ஜமீன்தார்),
              கன்னிசேர்வை,குத்தியார்,உனிசார்,உண்டேனவர்,உனிசிவார்,
              மார்களார்,ரெப்பேவார்,காங்கேவார்,கத்தேவார்,சிறையோர்,
              லோக்கனவார்,லாபேதார்,சீலயகன்னார்,பூசிவார்(பூசாரி),கோனேவார்,சாயார்,கரடியோர்,ஹாரப்பிதார்,தோண்டிவார்,
              என்று பல கூட்டங்கள் உள்ளன. மாமன் முறை உள்ள கூட்டதில் இருந்துதான்
              பெண் கொடுப்பது எடுப்பது என்ற வழக்கம் இவர்களிடம் உள்ளது.
            </p>
            <p>
              இவர்கள் தங்கள் திருமணங்களை மிக எளிமையாக நடத்தும் பழக்கம்
              கொண்டவர்கள். திருமணம் நடக்கும் இடத்துக்கு முதலில் மாப்பிள்ளை
              அழைக்கபடுகிறார். பின்னர் மணப்பெண் அழைக்கபடுகிறார். பெண் மூன்று
              முறை மாப்பிள்ளையை வலம் வந்து காலில் விழுந்து வணங்கிவிட்டு,
              கூட்டதாரை பார்த்து வணங்கிவிட்டு அமருவார். கூட்டதில் சாதிப்
              பெரியவர் 'இன்னார் பெண்ணுக்கும் இன்னார் பையனுக்கும் திருமணம்
              செய்விக்க இருவீட்டார் தாய்மாமன்கள் உட்பட சாதியாருக்கு சம்மதமா'
              என்று கேட்பார். கூட்டதார் சம்மதம் என்று சொல்ல 'அப்ப சரி மாலைய
              மாத்திகுங்க' என்று கூறுவார். பின்னர் மணமக்கள் மூன்றுமுறை மாலையை
              மாற்றிக் கொள்வார்கள் அதோடு திருமணம் முடிந்துவிடும். இவர்களிடம்
              திருமணத்தில் தாலி அணிவிக்கும் பழக்கமோ பிற சடங்குகளோ கிடையாது.
              திண்டுக்கல், கோவை பகுதியில் உள்ளவர்கள் தாலிக்குபதில் கருகுமணி
              அணியும் பழக்கத்தைக் கொண்டுள்ளார்கள்,தேனி,மதுரை மாவட்டத்தில் சில
              ஊர்களில் மட்டும் பிற சாதியினருடன் ஏற்பட்ட பழக்க வழக்கத்தால் தாலி
              அணிகின்றனர் என்றாலும் அதை அவர்கள் தாலியாக எடுத்துக் கொள்வதில்லை.
              இவர்கள் சாதியில் கைம்பெண் திருமணம் உண்டு. மண விலக்கும் உண்டு.{" "}
            </p>
            <p>
              ஒக்கலிகர் திருமணம் புவ்வை குட்டுதல் இவ்விழாவில் மணமகனின் தாய்மாமன்
              நெல்லை மரக்காலில் அளந்து போட வேண்டும். அக்குலத்தில் பிறந்த
              திருமணமான பெண்கள் (பிறந்த வீட்டு பிள்ளைகள்) ஐந்து பேர்
              அலங்கரிக்கப்பட்ட உலக்கையால் அந்த நெல்லை குற்றியவாறு சுற்றி வர
              வேண்டும். ஒரு சுற்றுக்கு ஒரு முறை தாய்மாமனின் மனைவி முறத்தால்
              புடைத்துச் சுத்தம் செய்ய வேண்டும். இவ்வாறே மூன்று முறை செய்த பின்
              ஐந்து பேரில் ஒருவர் கையில் உலக்கைகள் ஐந்தும் கொடுக்கப்பட்டு
              மற்றொருவரிடம் மூன்று முறை மாற்றி மாற்றி கொடுத்தபின் உலக்கைகள் பூஜை
              அறையில் ஒரு மூலையில் நிறுத்தி வைக்கப்பட வேண்டும். .. இன்றைய நம்
              இளம்தலைமுறையினருக்கு அன்பானவேண்டுகோள் ! தற்பொழுது கலப்புமணங்கள்
              அதிகரித்துள்ளது. தயவுசெய்து இளைஞர்கள் இருபாலரும் புழக்கத்திற்கு
              உதவாதவர்குளுடன் தொடர்பு ஏற்படுவதால் நம் பண்பாடு,கலாச்சாரம்
              மறக்கடிக்கப்பட்டு அழிக்கப்படும்..எனவே, ஒக்கலிகராக வந்தோம்
              ஒக்கலிகராக செல்லவேண்டும். நம் சமுதாய முன்னேற்றம் உங்கள் கையில்
              உள்ளது. கல்வியில் கவனம் செலுத்தி ஒரு நல்ல பணியில் சேர்ந்து
              பெற்றோரை கெளரவத்துடன் தலைநிமிர வைப்போம்.
            </p>
          </div>
          <div className="eduinfo">
            <p>
              1995 ஆம் ஆண்டு தென்மாவட்டங்களில் இருந்து கோவைக்கு தொழில்
              நிமித்தமாக குடியேறியவர்கள் ஒன்றிணையும் பொருட்டு ஒரு சங்கம் தொடங்க
              திரு.கனகராஜ் அவர்கள், திரு, ஜனார்த்தனன் அவர்கள், திரு,திருமுடி
              அவர்கள்,திரு,நந்தகோபால் ஆவார்கள்,திரு,ராமானுஜம்
              அவர்கள்,திரு,எஸ்.மணிகண்டன் அவர்கள்,திரு,எஸ்.கே.பாண்டியன்
              அவர்கள்,திரு,எத்திராஜ் அவர்கள் மற்றும் பல முன்னோடிகள் முயற்சி
              மேற்கொண்டு 31/07/1996 ல்சங்கம் தொடங்கப்பட்டு 19/09/1996 ல்
              தலைவர்-1, உப தலைவர்-1 , செயலாளர்-1,துணைச்செயலாளர்கள்-3,
              பொருளாளர்-1, துணைப்பொருளாளர்-1, நிர்வாகக்குழுவில்-17 பேர் எனவும்,
              மொத்த உறுப்பினர்கள் 105 என சங்கம் அமைத்து "கோவை வாழ் தென்மாவட்ட
              ஒக்கலிகர் முன்னேற்ற சங்கம்" என்னும் பெயரில் முறைப்படி பதிவு
              செய்யப்பட்டு சிறப்பாக செயல் பட்டுவருகிறது. ஏழை குழந்தைகளுக்கு இலவச
              பாடப்புத்தகம்,சீருடை,வசதி குறைந்த அரசு பள்ளிகளுக்கு உதவி எனவும்
              குழந்தைகளை ஊக்கப்படுத்தும் விதமாக வருடந்தோறும் தேர்வில் வெற்றி
              பெறும் குழந்தைகளுக்கு உதவித்தொகை,கேடயம் வழங்கி ஊக்கப்படுத்தி
              வருகிறது.
            </p>
            <p>
              அக்காலகட்டத்தில் உறுப்பினராக இணைய நுழைவுக் கட்டணமாக 100/- ம்,
              வருடச்சந்தாவாக 50/- ம் வசூலிக்கப்பட்டது. அடுத்த முயற்சியாக
              அறக்கட்டளையும் தொடங்கப்பட்டு அதன் மூலம் தேனி மாவட்டம் கம்பத்தில்
              பொங்கல் திருநாளை முன்னிட்டு வருடந்தோறும் நீர்மோர் வழங்கப்பட்டு
              வருகிறது, பள்ளிகளுக்கு பீரோ,சேர்,இருக்கைகள் வழங்கப்பட்டது, மருதமலை
              அமர்ஜோதி தொழுநோயாளிகளுக்கு உதவிகள் செய்யப்பட்டது. குறைந்த கட்டண
              ஆம்புலன்ஸ் சேவை, மருத்துவ ஆலோசணை கல்வி ஆலோசணை,திருமண தகவல்
              பரிமாற்றம், மற்றும் மாணவர் களை ஊக்கப்படுத்தும் செயல்பாடுகளிலும்
              தீவிரம் காட்டி வருகிறது. மருதமலையில் நமது சங்கத்திற்காக 7 1/2
              சென்ட் நிலம் வாங்கப்பட்டது. பின் 2013 ல் அந்த இடம் கைமாற்றம்
              செய்யப்பட்டு 10 சென்டாக வாங்கப்பட்டது.
            </p>
            <p>
              இடையில் ஒரு சில காரணங்களால் சங்க பதிவு தடைபட்ட காரணத்தால் மீண்டும்
              இச்சங்கம் "தென்மாவட்ட ஒக்கலிகர் (காப்பு) மகாஜன நல சங்கம்" என
              பெயரிட்டு 17/08/2011 ல் மீண்டும் பதிவு செய்யப்பட்டு திரு,சேகர்
              அவர்கள் தலைவராகவும்,திரு,உடையாளி அவர்கள் செயலாளராகவும்,திரு,கேசவன்
              அவர்கள் பொருளாளராகவும் மற்றும் நிர்வாகக்குழுவில் 17 பேரும் என 20
              பேர் நிர்வாகிகளாகக் கொண்டு அதே உற்சாகத்துடன் செயல்படத்தொடங்கியது.
              பின்னர் 2016 ல் திரு,குணசேகரன் அவர்கள் தலைவராகவும், 2022
              ல்திரு,வீமராஜ்அவர்கள் தலைவராகவும்,திரு,நந்தகுமார் அவர்கள்
              செயலாளராகவும்,திரு,விஜயன் அவர்கள் பொருளாளராகவும் திரு, சவரிநாதன்
              அவர்கள், திரு,சுருளிநாதன் அவர்கள் துணைத்தலைவர்
              களாகவும்,திரு,ரூபத்குமார் அவர்கள்,திரு,ராஜேஷ்கண்ணன் அவர்கள்
              இணைச்செயலாளர்களாகவும் மற்றும் 26 செயற்குழு உறுப்பினர்களுடன்
              நிர்வாகக்குழு இயங்கிவருகிறது.
            </p>
            <p>
              இதன் அடுத்த இலக்காக பல்வேறு பகுதிகளில் உள்ள நம் மக்கள் எளிதில்
              தொடர்பு கொள்ளவும்,தெரிந்து கொள்ளவும் உதவும் பொருட்டு
              தொடங்கப்பட்டது தான் www.thenmavattaokkaligarsangam.in என்ற
              இணையதளம். இதில் தங்கள் அனைவரும் தங்கள் தகவல்களை பதிவேற்ற
              வேண்டுகிறோம். நமது தென்மாவட்ட ஒக்கலிகர் (காப்பு)மகாஜன நல சங்கம்
              மாநில சங்கத்தில் அங்கம் வகிக்கும் பொருட்டு தீவிர முயற்சி எடுத்து
              வருகின்றனர் நமது நிர்வாகிகள்.
            </p>
            <p>வாழ்க வளமுடன் வளர்க ஒக்கலிகர் சமுதாயம்.</p>
          </div>
        </div>
      </div>
      <div className="member-head">
        <div className="member-head1">
          <h1>Our Historical Leaders</h1>
        </div>
      </div>
      <div className="fillpage">
        <div className="gridsplit">
          <div>
            <img src={Leaders1} alt="" className="historyleader" />
            <h3 className="leadername"> திரு T. திருமுடி</h3>
          </div>
          <div>
            <img src={Leaders2} alt="" className="historyleader" />
            <h3 className="leadername">திரு. டாக்டர் T. ராமானுஜம்</h3>
          </div>
          <div>
            <img src={Leaders3} alt="" className="historyleader" />
            <h3 className="leadername">திரு S.கனகராஜ்</h3>
          </div>
          <div>
            <img src={Leaders4} alt="" className="historyleader" />
            <h3 className="leadername">திரு S.மணிகண்டன்</h3>
          </div>
          <div>
            <img src={Leaders5} alt="" className="historyleader" />
            <h3 className="leadername">திரு R.K.சுரேஷ்</h3>
          </div>
          <div>
            <img src={Leaders6} alt="" className="historyleader" />
            <h3 className="leadername">திரு அமரர் எத்திராஜ்</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
