import { Contactus } from "../axios";

export const createContactus = async (data) => {
  var createContactus = await Contactus.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createContactus;
};
