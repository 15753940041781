import React from "react";
import Event from "../Component/Event/Event";
import Navbar from "../Component/Navbar";
import Footer from "../Component/Footer";

const Events = () => {
  return (
    <div>
      <Navbar />
      <Event />
      <Footer />
    </div>
  );
};

export default Events;
