import React from "react";
import "../Assets/Css/Footer.css";
import { MdLocationPin } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
import { HiMail } from "react-icons/hi";
import { BiLogoFacebook } from "react-icons/bi";
import { AiOutlineGoogle } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import footerimg from "../Assets/images/footerimages.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="footer">
        <div className="footer1">
          <div className="footer2">
            <img src={footerimg} alt="" />
            <p>Thenmaavatta Okkaligar (Kaappu) </p>
            <p>Mahajana Nala Sangam, Kovai</p>
          </div>
          <div className="footer3">
            <h1>Extra links</h1>
            <div className="footer31">
              <p
                onClick={() => {
                  navigate("/");
                }}
              >
                Home
              </p>

              <p
                onClick={() => {
                  navigate("/allevents");
                }}
              >
                Information
              </p>
              <p
                onClick={() => {
                  navigate("/office");
                }}
              >
                Office Bearers
              </p>
              <p
                onClick={() => {
                  navigate("/news");
                }}
              >
                News
              </p>
              <p
                onClick={() => {
                  navigate("/project");
                }}
              >
                Project
              </p>
            </div>
          </div>
          <div className="footer4">
            <h1>Contact Us</h1>
            <div className="footer32">
              <p>
                <MdLocationPin className="foot-icon2" />
                343, சத்தி ரோடு, புரோசோன்மால் எதிரில்,
              </p>
              <p name="lo">சிவானந்தாபுரம்,</p>
              <p name="lo">கோவை - 35</p>
              <p>
                <BsFillTelephoneFill className="foot-icon" />
                0422 3143231, +91 9790456785
              </p>
              <p>
                <HiMail className="foot-icon" />
                thenmavattaokkaligarsangam@gmail.com
              </p>
              <div className="foot-icon1">
                <BiLogoFacebook className="foot-icon2" />
                <AiOutlineGoogle className="foot-icon2" />
                <BsTwitter className="foot-icon2" />
                <BsYoutube className="foot-icon2" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <h1>Copyright © 2022 - Thenmaavatta Okkaligar Sangam.</h1>
      </div>
    </>
  );
};

export default Footer;
