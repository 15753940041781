import React from "react";
import Helplineimg from "../Assets/images/helpline.png";
import Navbar from "../Component/Navbar";
import Footer from "../Component/Footer";

const Helpline = () => {
  return (
    <>
      <Navbar />
      <div className="member-head">
        <div className="member-head1">
          <h1>Helpline Numbers</h1>
        </div>
      </div>
      <img src={Helplineimg} alt="" className="helplineimg" />
      <Footer />
    </>
  );
};

export default Helpline;
