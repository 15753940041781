import React from "react";
import Navbar from "../Component/Navbar";
import Members from "../Component/Members/Memberdetails";
import Footer from "../Component/Footer";

const Memberdetails = () => {
  return (
    <>
      <Navbar />
      <Members />
      <Footer />
    </>
  );
};

export default Memberdetails;
