import { Family } from "../axios";

export const viewallFamily = async (data) => {
  var viewallFamily = await Family.post(`/viewallfamily`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallFamily;
};

export const createFamily = async (data) => {
  var createFamily = await Family.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createFamily;
};

export const viewBymemberFamily = async (data) => {
  var viewBymemberFamily = await Family.post(`/viewBymember`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewBymemberFamily;
};

export const updateFamily = async (data) => {
  var updateFamily = await Family.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateFamily;
};

export const viewByidFamily = async (data) => {
  var viewByidFamily = await Family.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidFamily;
};

export const destroyFamily = async (data) => {
  var destroyFamily = await Family.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyFamily;
};
