import React, { useEffect, useState } from "react";
import HTMLReactParser from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../../Assets/Css/homenews.css";
import moment from "moment/moment";
import { viewallEvent } from "../../Api/event";

const Event = () => {
  const [allnews, setallnews] = useState([]);
  useEffect(() => {
    getAlldata();
  }, []);
  const getAlldata = async () => {
    var alldata = await viewallEvent();
    setallnews(alldata);
  };
  return (
    <>
      <div className="home-news">
        <div className="home-news1">
          <div className="home-news2">
            <h1>Latest Event</h1>
           
          </div>
        </div>
      </div>
      <div className="home-news-slider">
        <div className="home-news-slider1">
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            loop={true}
            autoplay={true}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
            }}
          >
            {allnews.length !== 0
              ? allnews.map((data, index) => (
                  <SwiperSlide className="home1-news" key={index}>
                    <div className="newsslider1">
                      <div className="imm">
                        <img src={data.image} alt="" />
                      </div>
                      <div className="news-scroll">
                        <div className="news-date">
                          <h1>{moment(data.date).format("DD")}</h1>
                          <div className="news-month">
                            <p>{moment(data.date).format("MMMM")}</p>
                            <p>{moment(data.date).format("YYYY")}</p>
                          </div>
                        </div>
                        <div className="news-contenthome">
                          <h1>{data.title}</h1>
                          <p className="shortlinenew">
                            {HTMLReactParser(data.description)}
                          </p>
                          <button
                            className="learnbtnleader"
                            onClick={() => {
                              window.location.replace(`/event`);
                              sessionStorage.setItem("eventid", data.id);
                            }}
                          >
                            Read More
                          </button>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Event;
