import React from "react";
import Navbar from "../Component/Navbar";
import Trade from "../Component/Project/Project";
import Footer from "../Component/Footer";

const Project = () => {
  return (
    <>
      <Navbar />
      <Trade />
      <Footer />
    </>
  );
};

export default Project;
