import { Educationfund } from "../axios";

export const viewallEducationfund = async (data) => {
  var viewallEducationfund = await Educationfund.post(
    `/viewalleducationfund`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallEducationfund;
};

export const createEducationfund = async (data) => {
  var createEducationfund = await Educationfund.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createEducationfund;
};

export const updateEducationfund = async (data) => {
  var updateEducationfund = await Educationfund.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateEducationfund;
};

export const destroyEducationfund = async (data) => {
  var destroyEducationfund = await Educationfund.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyEducationfund;
};

export const viewByidEducationfund = async (data) => {
  var viewByidEducationfund = await Educationfund.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidEducationfund;
};
