import React from "react";
import Navbar from "../Component/Navbar";
import Profile from "../Component/Profile/Profile";
import Footer from "../Component/Footer";

const Myprofile = () => {
  return (
    <>
      <Navbar />
      <Profile />
      <Footer />
    </>
  );
};

export default Myprofile;
