import { Kulam } from "../axios";

export const viewallKulam = async (data) => {
  var viewallKulam = await Kulam.post(`/viewallkulam`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallKulam;
};

export const createKulam = async (data) => {
  var createKulam = await Kulam.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createKulam;
};

export const viewByidKulam = async (data) => {
  var viewByidKulam = await Kulam.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidKulam;
};

export const destroyKulam = async (data) => {
  var destroyKulam = await Kulam.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyKulam;
};

export const updateKulam = async (data) => {
  var updateKulam = await Kulam.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateKulam;
};
