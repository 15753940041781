import React, { useEffect, useState } from "react";
import "../../Assets/Css/Galleryslider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { viewallGallery } from "../../Api/gallery";
import Modal from "react-modal";
import ReactPlayer from "react-player";

const Galleryslider = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const [gallerydata, setgallerydata] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [swiperdata, setswiperdata] = React.useState(true);
  const [viewimgdata, setviewimgdata] = React.useState(null);

  useEffect(() => {
    getGallerydata();
  }, []);
  const getGallerydata = async () => {
    var gallerydata = await viewallGallery();
    setgallerydata(gallerydata);
  };
  const viewimg = async (data) => {
    setswiperdata(false);
    setviewimgdata(data);
    setIsOpen(true);
  };
  function closeModal() {
    setIsOpen(false);
    setswiperdata(true);
  }
  return (
    <>
      <div className="gallery-title">
        <div className="gallery1">
          <h1>GALLERY</h1>
        </div>
      </div>
      <div className="galery-slider">
        {swiperdata === true ? (
          <div className="galery-slider1">
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              loop={true}
              autoplay={true}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
            >
              {gallerydata.length !== 0
                ? gallerydata.map((data, index) =>
                    data.image !== null ? (
                      <SwiperSlide
                        className="slides1"
                        key={index}
                        onClick={() => viewimg(data.image)}
                      >
                        <img src={data.image} alt="" />
                      </SwiperSlide>
                    ) : (
                      <SwiperSlide className="slides1" key={index}>
                        <ReactPlayer
                          url={data.url}
                          controls={true}
                          width="380px"
                          height="250px"
                          className="reactplayerdiv"
                        />
                      </SwiperSlide>
                    )
                  )
                : null}
            </Swiper>
          </div>
        ) : null}
      </div>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        className="gallerymodel"
      >
        <div>
          <div>
            <button className="closebtn" onClick={closeModal}>
              ✕
            </button>
            <h3 className="modelhead">Gallery View</h3>
          </div>
          <img src={viewimgdata} alt="" className="galleryimg" />
        </div>
      </Modal>
    </>
  );
};

export default Galleryslider;
