import { Member } from "../axios";

export const loginMember = async (data) => {
  var loginMember = await Member.post(`/login`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return loginMember;
};

export const createMember = async (data) => {
  var createMember = await Member.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createMember;
};

export const viewByidMember = async (data) => {
  var viewByidMember = await Member.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidMember;
};

export const updateMember = async (data) => {
  var updateMember = await Member.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateMember;
};

export const viewallMember = async (data) => {
  var viewallMember = await Member.post(`/viewallmember`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallMember;
};

export const viewByphoneMember = async (data) => {
  var viewByphoneMember = await Member.post(`/viewByphone`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByphoneMember;
};
