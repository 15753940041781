/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../Assets/Css/Education.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { viewByidMaruthamalaioffice } from "../../Api/maruthamalaioffice";
import moment from "moment";
import HTMLReactParser from "html-react-parser";

const Education = () => {
  const [projectdata, setprojectdata] = useState([]);
  var id = sessionStorage.getItem("maruthamalaiid");
  useEffect(() => {
    getsingledata();
  }, [id]);
  const getsingledata = async () => {
    if (id !== null) {
      var singledata = await viewByidMaruthamalaioffice({ id: id });
      setprojectdata(singledata);
    }
  };
  return (
    <>
      {projectdata.length !== 0 ? (
        <div className="fillpage">
          <div className="fillpage1">
            <div className="educnt">
              <div className="edu-image">
                <img src={JSON.parse(projectdata[0].image)[0]} alt="" />
              </div>
              <h3>{projectdata[0].title}</h3>
              <p>
                {moment(projectdata[0].date).format("MMMM DD, YYYY")} -{""}{" "}
                {projectdata[0].place}
              </p>
            </div>
            <div className="eduinfo">
              <p>{HTMLReactParser(projectdata[0].description)}</p>
            </div>
          </div>
          <div className="education-slider">
            <div className="edu-gallery">
              <h1>Gallery</h1>
              <div className="edu-gallery1">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={1}
                  loop={true}
                  autoplay={true}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                  }}
                >
                  {JSON.parse(projectdata[0].image).length !== 0
                    ? JSON.parse(projectdata[0].image).map((datanew, index) => (
                        <SwiperSlide className="edu-slider" key={index}>
                          <img src={datanew} alt="" />
                        </SwiperSlide>
                      ))
                    : null}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Education;
