import { Maruthamalaioffice } from "../axios";

export const viewallMaruthamalaioffice = async (data) => {
  var viewallMaruthamalaioffice = await Maruthamalaioffice.post(
    `/viewallmaruthamalaioffice`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallMaruthamalaioffice;
};

export const createMaruthamalaioffice = async (data) => {
  var createMaruthamalaioffice = await Maruthamalaioffice.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createMaruthamalaioffice;
};

export const updateMaruthamalaioffice = async (data) => {
  var updateMaruthamalaioffice = await Maruthamalaioffice.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateMaruthamalaioffice;
};

export const destroyMaruthamalaioffice = async (data) => {
  var destroyMaruthamalaioffice = await Maruthamalaioffice.post(
    `/destroy`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyMaruthamalaioffice;
};

export const viewByidMaruthamalaioffice = async (data) => {
  var viewByidMaruthamalaioffice = await Maruthamalaioffice.post(
    `/viewByid`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidMaruthamalaioffice;
};
