import React from "react";
import Navbar from "../Component/Navbar";
import Family from "../Component/Family/Family";
import Footer from "../Component/Footer";

const Myfamily = () => {
  return (
    <>
      <Navbar />
      <Family />
      <Footer />
    </>
  );
};

export default Myfamily;
