import { Officebearers } from "../axios";

export const viewallOfficebearers = async (data) => {
  var viewallOfficebearers = await Officebearers.post(
    `/viewallofficebearers`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallOfficebearers;
};

export const createOfficebearers = async (data) => {
  var createOfficebearers = await Officebearers.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createOfficebearers;
};

export const viewByidOfficebearers = async (data) => {
  var viewByidOfficebearers = await Officebearers.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidOfficebearers;
};

export const destroyOfficebearers = async (data) => {
  var destroyOfficebearers = await Officebearers.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyOfficebearers;
};
