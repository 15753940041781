/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import HTMLReactParser from "html-react-parser";
import { viewByidMember } from "../../Api/member";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import memimages from "../../Assets/images/234 3.png";

const Companydetails = () => {
  const [singledata, setsingledata] = useState([]);

  var id = sessionStorage.getItem("memberdetailsid");
  useEffect(() => {
    getsingledata();
  }, [id]);
  const getsingledata = async () => {
    if (id !== null) {
      var singlemember = await viewByidMember({ id: id });
      setsingledata(singlemember);
    }
  };
  const viewprofile = async (e) => {
    window.location.replace(`/memberdetails`);
    sessionStorage.setItem("memberdetailsid", e);
  };
  return (
    <>
      {singledata.length !== 0 ? (
        singledata[0].Companies.length !== 0 ? (
          <>
            <div className="member-head">
              <div className="member-head1">
                <h1>Company Information</h1>
              </div>
            </div>
            <div className="companydiv">
              <img
                src={singledata[0].Companies[0].cover}
                alt=""
                className="coverimgcompany"
              />
              <div className="companynewdiv">
                <div className="companydes">
                  <h1 className="title">{singledata[0].Companies[0].name}</h1>
                  <h6 className="location">
                    {singledata[0].Companies[0].city}
                  </h6>
                  <p className="description">
                    {HTMLReactParser(singledata[0].Companies[0].description)}
                  </p>
                </div>
                <div className="contatable">
                  <h5 className="contacttag">Contact Details:</h5>
                  <div className="mem-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Phone</th>
                          <td>{singledata[0].Companies[0].phone}</td>
                        </tr>
                        <tr>
                          <th>Email</th>
                          <td>{singledata[0].Companies[0].email}</td>
                        </tr>
                        <tr>
                          <th>Address</th>
                          <td>
                            {singledata[0].Companies[0].address},{" "}
                            {singledata[0].Companies[0].city},{" "}
                            {singledata[0].Companies[0].state},{" "}
                            {singledata[0].Companies[0].pincode}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {singledata.length !== 0 ? (
                    <div className="member-card1 profilediv">
                      <div className="member-box1">
                        <div className="member-img">
                          <img src={singledata[0].profile} alt="" />
                        </div>
                        <div className="member-img-con">
                          <h2>
                            {singledata[0].firstname} {singledata[0].lastname}
                          </h2>
                          <p>{singledata[0].occupation}</p>
                          <p
                            className="viewprofile"
                            onClick={() => viewprofile(singledata[0].id)}
                          >
                            View Profile
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="mem-detail2">
            {singledata.length !== 0 ? (
              <div className="mem-detail13">
                <div className="mem-images">
                  <div className="mem-images1">
                    <img
                      src={
                        singledata[0].profile == null
                          ? memimages
                          : singledata[0].profile
                      }
                      alt=""
                    />
                  </div>
                  <div className="mem-contents">
                    <h1>
                      {singledata[0].firstname} {singledata[0].lastname}
                    </h1>
                    <h3>{singledata[0].role}</h3>
                  </div>
                </div>
                <div className="mem-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Role</th>
                        <td>{singledata[0].role}</td>
                      </tr>
                      <tr>
                        <th>D.O.B</th>
                        <td>{singledata[0].dob}</td>
                      </tr>
                      <tr>
                        <th>Phone</th>
                        <td>+91 {singledata[0].phone}</td>
                      </tr>
                      <tr>
                        <th>Email</th>
                        <td>{singledata[0].email}</td>
                      </tr>
                      <tr>
                        <th>Occupation</th>
                        <td>{singledata[0].occupation}</td>
                      </tr>
                      <tr>
                        <th>State</th>
                        <td>
                          {" "}
                          {singledata[0].state === null
                            ? "-"
                            : singledata[0].state}
                        </td>
                      </tr>
                      <tr>
                        <th>City</th>
                        <td>
                          {" "}
                          {singledata[0].city === null
                            ? "-"
                            : singledata[0].city}
                        </td>
                      </tr>
                      <tr>
                        <th>Caste</th>
                        <td>
                          {" "}
                          {singledata[0].cast === null
                            ? "-"
                            : singledata[0].cast}
                        </td>
                      </tr>
                      <tr>
                        <th>Kulam</th>
                        <td>
                          {" "}
                          {singledata[0].kolom === null
                            ? "-"
                            : singledata[0].kolom}
                        </td>
                      </tr>
                      {/* <tr>
                        <th>Kothiram</th>
                        <td>
                          {singledata[0].kothiram === null
                            ? "-"
                            : singledata[0].kothiram}
                        </td>
                      </tr> */}
                      <tr>
                        <th>Jathagam</th>
                        <td>
                          {singledata[0].jathagam !== null ? (
                            <button
                            //   onClick={() => {
                            //     download(singledata[0].jathagam);
                            //   }}
                            >
                              Download
                            </button>
                          ) : (
                            "Not Available"
                          )}
                        </td>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
        )
      ) : null}
    </>
  );
};

export default Companydetails;
